import allCountries from "../resources/allCountries.json";

export default function(codeOrFullName) {
  if (codeOrFullName && codeOrFullName.length === 2) {
    const country = allCountries.find(
      country => country.abbrv === codeOrFullName
    );

    return country ? country.fullName : "";
  } else {
    const country = allCountries.find(
      country => country.fullName === codeOrFullName
    );

    return country ? country.abbrv : "";
  }
}
