<template>
  <mc-modal
    open
    backdropcloseactiondisabled
    data-test="add-document-modal"
    width="720px"
    height="600px"
    @closing="onModalClose"
  >
    <div slot="heading">{{ labels.addDocument }}</div>
    <div class="body">
      <mc-input
        data-test="doc-modal-name"
        name="name"
        :label="labels.name"
        :value="name"
        clearbutton
        keepclearbuttonvisible
        @clearbuttonclick="updateName"
        @input="updateName"
      >
      </mc-input>
      <mc-select
        data-test="doc-modal-type"
        name="type"
        :label="labels.type"
        :placeholder="labels.placeholder"
        :value="type"
        @optionselected="updateType"
      >
        <mc-option
          v-for="type in customDocsTypesList"
          :key="type.code"
          :value="type.name"
        >
          {{ type.name }}
        </mc-option>
      </mc-select>
      <mc-textarea
        data-test="doc-modal-description"
        name="description"
        :label="labels.description"
        :hint="labels.descriptionHint"
        :value="description"
        @keyup="updateDescription"
      ></mc-textarea>
      <mc-radio-group
        data-test="doc-modal-requirement"
        name="requirement"
        :legend="labels.requirement"
        @change="updateIsMandatory"
      >
        <mc-radio
          name="requirement"
          value="true"
          :label="labels.mandatory"
          :checked="isMandatory"
        ></mc-radio>
        <mc-radio
          name="requirement"
          value="false"
          :label="labels.optional"
          :checked="isMandatory !== undefined && !isMandatory"
        ></mc-radio>
      </mc-radio-group>
    </div>
    <mc-button
      data-test="doc-modal-cancel"
      appearance="Neutral"
      variant="filled"
      slot="secondaryAction"
      dialogaction="cancel"
      :label="labels.cancel"
    ></mc-button>
    <mc-button
      data-test="doc-modal-save"
      slot="primaryAction"
      dialogaction="ok"
      :disabled="disableSave"
      :label="labels.addDocument"
      @click="saveChanges()"
    ></mc-button>
  </mc-modal>
</template>
    
<script>
import i18nFilter from "../../../../filters/i18nFilter";
import "@maersk-global/mds-components-core/mc-modal";
import "@maersk-global/mds-components-core/mc-button";
import "@maersk-global/mds-components-core/mc-icon";
import "@maersk-global/mds-components-core/mc-input";
import "@maersk-global/mds-components-core-select";
import "@maersk-global/mds-components-core-textarea";
import "@maersk-global/mds-components-core-radio-group";
import "@maersk-global/mds-components-core-radio";
import { constants } from "@/utilities/CONSTANTS";

export default {
  name: "CustomsDocsModal",
  props: {
    document: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      name: "",
      type: "",
      description: "",
      isMandatory: undefined,
      customDocsTypesList: constants.CUSTOM_DOC_TYPES,
    };
  },
  computed: {
    labels() {
      const prefix =
        "chbAdmin.editPage.form.editCountry.onboarding.customsDocs";
      return {
        addDocument: this.document
          ? i18nFilter(`${prefix}.common.updateDocument`)
          : i18nFilter(`${prefix}.common.addDocument`),
        name: i18nFilter(`${prefix}.modal.name`),
        type: i18nFilter(`${prefix}.common.type`),
        placeholder: i18nFilter(`${prefix}.modal.type.placeholder`),
        description: i18nFilter(`${prefix}.modal.description`),
        descriptionHint: i18nFilter(`${prefix}.modal.descriptionHint`),
        requirement: i18nFilter(`${prefix}.modal.requirement`),
        mandatory: i18nFilter(`${prefix}.modal.mandatory`),
        optional: i18nFilter(`${prefix}.modal.optional`),
        cancel: i18nFilter(`chbAdmin.common.cancel`),
      };
    },
    disableSave() {
      const isNameChanged = this.document?.name !== this.name;
      const isTypeChanged = this.document?.type?.name !== this.type;
      const isDescriptionChanged =
        this.document?.description !== this.description;
      const isMandatoryChanged = this.document?.mandatory !== this.isMandatory;
      const isRequiredDataPresent =
        this.name && this.type && typeof this.isMandatory !== "undefined";
      const isDataUpdated =
        isNameChanged ||
        isTypeChanged ||
        isDescriptionChanged ||
        isMandatoryChanged;
      return !isRequiredDataPresent || !isDataUpdated;
    },
  },
  mounted() {
    if (this.document) {
      this.populateDocumentInfo();
    }
  },
  methods: {
    updateName(e) {
      this.name = e.target.value;
    },
    updateType(e) {
      this.type = e.target.value;
    },
    updateDescription(e) {
      this.description = e.target.value;
    },
    updateIsMandatory(e) {
      this.isMandatory = e.target.value === "true";
    },
    onModalClose() {
      this.$emit("closing");
    },
    populateDocumentInfo() {
      this.name = this.document.name;
      this.type = this.document.type.name;
      this.description = this.document.description;
      this.isMandatory = this.document.mandatory;
    },
    saveChanges() {
      this.$emit("saveDocument", {
        name: this.name,
        type: {
          code: this.customDocsTypesList.find((type) => type.name === this.type)
            .code,
          name: this.type,
        },
        description: this.description,
        mandatory: this.isMandatory,
      });
    },
  },
};
</script>
    
<style scoped lang="scss">
.body {
  max-width: 400px;
  > * {
    display: block;
    margin-bottom: 16px;
  }
}
</style>
    