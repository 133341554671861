import Vue from "vue";
import i18nFilter from "../../filters/i18nFilter";
import { appUtilities } from "@/utilities/common";

export const eventBus = new Vue({
  methods: {
    sortCountries(order, countries) {
      const values = [...countries];
      if (order === i18nFilter("chbAdmin.subHeader.sort.desc")) {
        this.$emit("sortCountries", appUtilities.sortByDesc(values, 'countryName'));
      } else {
        this.$emit("sortCountries", appUtilities.sortByAsc(values, 'countryName'));
      }
    }
  }
});
