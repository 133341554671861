import i18nFilter from './filters/i18nFilter';
import { handlers } from './validatorHandlers';

const validateHandler = (value, handler) => {
  return handlers[handler](value);
};

export default (validators, contractForm, context, setErrorTextFunction) => {
  let isFormValid = true;
  for (let prop in validators) {
    let invalidProp = null;
    if (validators[prop].length) {
      validators[prop].forEach(validator => {
        const value = contractForm[prop].value;
        const isValid = validateHandler(value, validator);

        if (!isValid) {
          isFormValid = false;
          invalidProp = {
            propName: prop,
            validator
          };
        }
      });
      if (invalidProp) {
        const errorText = i18nFilter(`chbAdmin.form.${context}.${prop}.validationError.${invalidProp.validator}`);
        setErrorTextFunction({ prop, isValid: false, errorText });
      } else {
        setErrorTextFunction({ prop, isValid: true, errorText: '' });
      }
    } else {
      setErrorTextFunction({ isValid: true, errorText: '' });
    }
  }
  return isFormValid;
};
