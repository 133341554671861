<template>
  <ChbSection>
    <div v-if="ports.length">
      <div class="ports-table-header-labels">
        <div>
          {{ "chbAdmin.editPage.form.showPorts.label" | i18n }}
        </div>
        <div>{{
            "chbAdmin.editPage.form.editCountry.productFormTable.supportedBrands.MAEU.label"
              | i18n
          }}
        </div>
        <div>{{
            "chbAdmin.editPage.form.editCountry.productFormTable.supportedBrands.SEAU.label"
              | i18n
          }}
        </div>
        <div>{{
            "chbAdmin.editPage.form.editCountry.productFormTable.supportedBrands.TWIL.label"
              | i18n
          }}
        </div>
        <div>{{
            "chbAdmin.editPage.form.editCountry.productFormTable.supportedBrands.HSUD.label"
              | i18n
          }}
        </div>
        <div>{{
            "chbAdmin.editPage.form.editCountry.portChargesForm.price.label"
              | i18n
          }}
          ({{ currencyCode }})
        </div>
        <div>{{
            "chbAdmin.editPage.form.editCountry.portChargesForm.per.label"
              | i18n
          }}
        </div>
        <div>{{
            "chbAdmin.editPage.form.editCountry.portChargesForm.chargeType"
              | i18n
          }}
        </div>
      </div>
    </div>
    <div class="ports-table">
      <div v-for="(port, index) in ports" :key="index">
        <ChbPortChargesFormTableItem
          :port="port"
          :portGroup="portGroup"
          :productId="productId"
          :productGroup="productGroup"
          :currentProductPorts="ports"
        />
      </div>
    </div>
    <div class="ports-action-buttons">
      <a href="javascript:void(0)" @click.prevent="handleAddPort()"
      ><h5>
        +
        {{
          `chbAdmin.editPage.form.editCountry.portChargesForm.button.${portGroup}.addPort`
            | i18n
        }}
      </h5>
      </a>
    </div>
  </ChbSection>
</template>

<script>
import ChbSection from "../../common/ChbSection.vue";
import ChbPortChargesFormTableItem from "./ChbPortChargesFormTableItem";

import i18nFilter from "../../../filters/i18nFilter";

import {createPortChargeFormObject} from "@/formUtilities";
import {mapActions, mapState} from "vuex";

export default {
  name: "ChbPortChargesFormTable",
  components: {
    ChbPortChargesFormTableItem,
    ChbSection
  },
  props: {
    portGroup: {type: String, required: true},
    // label: { type: String, required: false },
    ports: {type: Array, required: true},
    productId: {type: String, required: false},
    productGroup: {type: String, required: false}
  },
  computed: {
    ...mapState({
      currencyCode: state =>
        state.editCountryController.formData.currencyCode.value
    })
  },
  methods: {
    ...mapActions(["openModal"]),
    handleAddPort: function () {
      const modalOptions = {
        contentComponent: "ChbConfigurePortChargesForm",
        data: {
          portGroup: this.portGroup,
          productId: this.productId,
          productGroup: this.productGroup,
          isNewPort: true,
          isFormValidated: false,
          isFormValid: true,
          formFields: createPortChargeFormObject(),
          formValidators: {
            portName: ["required"]
          },
          formError: {
            isVisible: false,
            errorText: ""
          }
        },
        headerText: i18nFilter(
          "chbAdmin.editPage.modal.addPortCharges.header.label"
        ),
        submitButtonOptions: {
          isDisabled: false,
          label: i18nFilter(
            "chbAdmin.editPage.modal.configurePortCharges.button.submit.label"
          )
        },
        deleteButtonOptions: {
          isVisible: true,
          isDisabled: true,
          label: i18nFilter(
            "chbAdmin.editPage.modal.configurePortCharges.button.delete.label"
          )
        }
      };
      this.openModal(modalOptions);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/settings";

.ports-table-header-labels {
  width: 85%;
  display: grid;
  grid-template-columns: 18% 10% 10% 8% 18% 15% 8% 10%;
  font-size: 14px;
  line-height: 20px;
  color: #878787;

  .ports-price-label {
    @include grid-column-offset(5);
    @include grid-col(3);
  }

  .ports-per-label {
    @include grid-col(2);
  }
}

.ports-action-buttons {
  margin-top: 32px;
  margin-bottom: -16px;

  h5 {
    display: inline-block;
    cursor: pointer;
    color: #0073ab;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
  }
}
</style>
