function getDefaultExpiryDate () {
  const defaultExpiry = new Date();
  // default cookie expire date set to 1 year
  defaultExpiry.setTime(defaultExpiry.getTime() + (365 * 24 * 60 * 60 * 1000));
  return defaultExpiry;
}

export const cookie = {
  setCookie (cookieName, cookieValue, expiry) {
    const expiryDate = expiry || getDefaultExpiryDate();
    document.cookie = `${cookieName}=${cookieValue};expires=${expiryDate.toUTCString()};path=/`;
  },

  getCookie (cookieName) {
    const name = cookieName + '=';
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
};
