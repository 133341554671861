<template>
  <div id="chb-edit-page-header">
    <ChbSection>
      <p class="breadcrumb">
        <a href="javascript:void(0);" @click="handleBackClicked">{{
          header
        }}</a>
        <span
          >{{ "chbAdmin.editPage.header.slash" | i18n
          }}{{ "chbAdmin.editPage.header.title" | i18n }}
          {{ "chbAdmin.editPage.header.hyphen" | i18n }}
          {{ countryName }}
        </span>
      </p>
    </ChbSection>
    <ChbSection>
      <div class="heading">
        <h2 class="font--display-2">
          {{ "chbAdmin.editPage.header.title" | i18n }}
          {{ "chbAdmin.editPage.header.hyphen" | i18n }} {{ countryName }}
        </h2>
      </div>
      <div v-if="!enableOnboarding" class="country-info-title">
        <h1>
          {{ "chbAdmin.editPage.header.subHeading.products" | i18n }}
        </h1>
      </div>
    </ChbSection>
  </div>
</template>

<script>
import ChbSection from "../../common/ChbSection.vue";
import { mapState, mapActions } from "vuex";
import i18nFilter from "../../../filters/i18nFilter";
import { constants } from "@/utilities/CONSTANTS";
import { isFeatureEnabled } from "@/utilities/featureFlags";

export default {
  name: "ChbEditPageHeader",
  components: { ChbSection },
  data() {
    return {
      header: i18nFilter("chbAdmin.editpage.header"),
    };
  },
  computed: {
    ...mapState({
      countryName: (state) =>
        state.editCountryController.formData.countryName.value,
      isFormDataChanged: (state) =>
        state.editCountryController.isFormDataChanged,
    }),
    enableOnboarding() {
      return isFeatureEnabled(constants.featureFlags.ONBOARDING.name);
    },
  },
  methods: {
    ...mapActions([
      "goToCountriesOverviewPage",
      "openLeavingWithoutSavingModal",
    ]),
    handleBackClicked() {
      if (this.isFormDataChanged) {
        this.openLeavingWithoutSavingModal();
      } else {
        this.goToCountriesOverviewPage();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  margin: 24px 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  a {
    text-decoration: none;
    color: #0073ab;
    &:hover {
      text-decoration: underline;
    }
  }
  span {
    color: #0073ab;
  }
}
.country-info-title {
  margin-top: 40px;
  font-size: 26px;
  line-height: 32px;
}
</style>
