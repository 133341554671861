<template>
  <mc-modal
    open
    backdropcloseactiondisabled
    data-test="delete-modal"
    @closing="onModalClose"
  >
    <div slot="heading"><slot name="heading"></slot></div>
    <div class="body"><slot name="content"></slot></div>
    <mc-button
      appearance="Neutral"
      variant="filled"
      slot="secondaryAction"
      dialogaction="cancel"
      ><slot name="cancel-button"></slot
    ></mc-button>
    <mc-button slot="primaryAction" dialogaction="ok" @click="confirmDeletion()"
      ><slot name="confirm-button"></slot
    ></mc-button>
  </mc-modal>
</template>
    
<script>
import i18nFilter from "../../../../filters/i18nFilter";
import "@maersk-global/mds-components-core/mc-modal";
import "@maersk-global/mds-components-core/mc-button";

export default {
  name: "DeleteModal",
  components: {},
  data() {
    return {};
  },
  computed: {
    labels() {
      const prefix =
        "chbAdmin.editPage.form.editCountry.onboarding.customsDocs";
      return {
        addDocument: this.document
          ? i18nFilter(`${prefix}.common.updateDocument`)
          : i18nFilter(`${prefix}.common.addDocument`),
        name: i18nFilter(`${prefix}.modal.name`),
        type: i18nFilter(`${prefix}.common.type`),
        placeholder: i18nFilter(`${prefix}.modal.type.placeholder`),
        description: i18nFilter(`${prefix}.modal.description`),
        descriptionHint: i18nFilter(`${prefix}.modal.descriptionHint`),
        requirement: i18nFilter(`${prefix}.modal.requirement`),
        mandatory: i18nFilter(`${prefix}.modal.mandatory`),
        optional: i18nFilter(`${prefix}.modal.optional`),
        cancel: i18nFilter(`chbAdmin.common.cancel`),
      };
    },
  },
  methods: {
    onModalClose() {
      this.$emit("closing");
    },
    confirmDeletion() {
      this.$emit("confirmDelete");
    },
  },
};
</script>
    
<style scoped lang="scss">
</style>
    