import _ from "lodash";

const brandNames = {
  MAEU: "maersk",
  SEAU: "sealand",
  SAFM: "safmarine"
};

const getDefaultBrandConfiguration = () => [
  {
    brandCode: "MAEU",
    supportedProductGroups: []
  },
  {
    brandCode: "SEAU",
    supportedProductGroups: []
  },
  {
    brandCode: "TWIL",
    supportedProductGroups: []
  },
  {
    brandCode: "HSUD",
    supportedProductGroups: []
  }
];

export const supportedCarrierCodesBySystem = [
  "MAEU",
  "SEAU",
  "TWIL",
  "HSUD"
];

export const getMappedChargeType = chargeType => {
  const chargeTypeEnums = ["BILL_OF_LADING", "TEU", "CONTAINER"];
  const chargeTypeVisualRepresentation = ["B/L", "TEU", "Container"];

  const indexOfChargeTypeEnum = chargeTypeEnums.indexOf(chargeType);
  const indexOfChargeTypeVisual = chargeTypeVisualRepresentation.indexOf(
    chargeType
  );

  if (indexOfChargeTypeEnum !== -1) {
    return chargeTypeVisualRepresentation[indexOfChargeTypeEnum];
  } else if (indexOfChargeTypeVisual !== -1) {
    return chargeTypeEnums[indexOfChargeTypeVisual];
  }
};

export const getMappedChargesFor = chargeType => {
  let word = chargeType.replace(/_/g, " ");
  return word.charAt(0) + word.substring(1).toLowerCase();
};

const getDefaultCountryStructure = () => ({
  countryCode: "",
  countryName: "",
  currencyCode: "",
  countryContactEmail: "",
  countryContactPhone: "",
  countryUploadDocumentEmail: "",
  productGroups: [
    {
      productGroupId: "importCustomsClearance",
      products: []
    },
    {
      productGroupId: "exportCustomsClearance",
      products: []
    }
  ]
});

const getDefaultProductStructure = () => ({
  defaultProduct: false,
  productId: "",
  productName: "",
  productDescription: "",
  supportedShipmentServiceModes: [],
  ports: [],
  chargeCode: ""
});

const getDefaultPortChargeStructure = () => ({
  portId: "",
  geoId: "",
  portName: "",
  chargeLines: [],
  supportedCarrierCodes: []
});

export const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    let r = (Math.random() * 16) | 0;
    let v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// Function that accepts array of strings which represent property names in returned object
// Each property will be an object which has value, isValid and errorText properties
export const createFormObject = arrayOfProperties =>
  arrayOfProperties.reduce((aggr, curr) => {
    aggr[curr] = {
      value: "",
      isValid: true,
      errorText: ""
    };

    return aggr;
  }, {});

// Function that accepts country object and returns the form object with all the necessary fields
// Returned object must match properties in default country structure
export const createCountryFormObject = country => {
  let countryData = _.cloneDeep(country);
  const countryFormObject = {};
  const defaultCountryStructure = getDefaultCountryStructure();

  for (let prop in defaultCountryStructure) {
    countryFormObject[prop] = {
      value: countryData[prop] || defaultCountryStructure[prop],
      isValid: true,
      errorText: ""
    };
  }

  // This function mutates countryFormObject
  // addSupportedBrandsFlagsToCountryObject(
  //   countryData.configuration,
  //   countryFormObject
  // );
  return countryFormObject;
};

export const createPortObject = port => {
  let portData = _.cloneDeep(port);
  const portFormObject = {};
  const defaultPortStructure = getDefaulPortStructure();

  for (let prop in defaultPortStructure) {
    portFormObject[prop] = {
      value: portData[prop] || defaultPortStructure[prop],
      isValid: true,
      errorText: ""
    };
  }
  return portFormObject;
};

const getDefaulPortStructure = () => ({
  portId: "",
  geoId: "",
  portName: "",
  chargeLines: [],
  supportedCarrierCodes: [],
  productGroup: "",
  productId: ""
});

export const createProductFormObject = product => {
  let productData = _.cloneDeep(product);
  const productFormObject = {};
  const defaultProductStructure = getDefaultProductStructure();

  if (!productData) {
    productData = defaultProductStructure;
  }

  for (let prop in defaultProductStructure) {
    productFormObject[prop] = {
      value: productData[prop] || defaultProductStructure[prop],
      isValid: true,
      errorText: ""
    };
  }

  if (!productFormObject.productId.value) {
    productFormObject.productId.value = generateUUID();
  }
  return productFormObject;
};

export const createPortChargeFormObject = portCharge => {
  let portChargeData = _.cloneDeep(portCharge);
  const portChargeFormObject = {};
  const defaultPortChargeStructure = getDefaultPortChargeStructure();

  if (!portChargeData) {
    portChargeData = defaultPortChargeStructure;
  }

  for (let prop in defaultPortChargeStructure) {
    portChargeFormObject[prop] = {
      value: portChargeData[prop] || defaultPortChargeStructure[prop],
      isValid: true,
      errorText: ""
    };
  }

  if (!portChargeFormObject.portId.value) {
    portChargeFormObject.portId.value = generateUUID();
  }

  return portChargeFormObject;
};

export const extractDataFromForm = formData => {
  const countryData = {};

  for (let prop in formData) {
    countryData[prop] = formData[prop].value;
  }

  return countryData;
};

// We are changing the structure of country object and adding configuration object instead of brand flags
export const extractCountryDataFromForm = countryFormData => {
  const countryData = {
    configuration: getDefaultBrandConfiguration()
  };
  countryFormData.productGroups.value.forEach(ele => {
    if (
      (ele.productGroupId === "importCustomsClearance" ||
        ele.productGroupId === "exportCustomsClearance") &&
      ele.products.length > 0
    ) {
      const productGroup =
        ele.productGroupId === "importCustomsClearance"
          ? "importCustomsClearance"
          : "exportCustomsClearance";
      ele.products.forEach(product => {
        //replace % to %%- reason - not to break the Play framework in SSP as a single '%' is a special char in Play templates.
        if (product.productDescription) {
          product.productDescription = product.productDescription.replace(
            /%/g,
            "%%"
          );
        }
        if (product.ports.length > 0) {
          product.ports.forEach(port => {
            if (port.supportedCarrierCodes.length > 0) {
              port.supportedCarrierCodes.forEach(code => {
                const matchedBrand = countryData.configuration.find(
                  el => el.brandCode === code
                );
                if (
                  matchedBrand &&
                  !matchedBrand.supportedProductGroups.includes(productGroup)
                ) {
                  matchedBrand.supportedProductGroups.push(productGroup);
                }
              });
            }
          });
        }
      });
    }
  });
  for (let prop in countryFormData) {
    countryData[prop] = countryFormData[prop].value;
  }
  return countryData;
};
