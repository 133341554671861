import { render, staticRenderFns } from "./chbProductDetails.vue?vue&type=template&id=7a510e9b&scoped=true"
import script from "./chbProductDetails.vue?vue&type=script&lang=js"
export * from "./chbProductDetails.vue?vue&type=script&lang=js"
import style0 from "./chbProductDetails.vue?vue&type=style&index=0&id=7a510e9b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a510e9b",
  null
  
)

export default component.exports