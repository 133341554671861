<template>
  <div>
    <ChbBasicCountryDataFields />
    <ChbProductsForm />
  </div>
</template>

<script>
import ChbBasicCountryDataFields from "./ChbBasicCountryDataFields";
import ChbProductsForm from "./ChbProductsForm";
import { mapActions } from "vuex";

export default {
  name: "ChbEditCountryForm",
  components: {
    ChbBasicCountryDataFields,
    ChbProductsForm
  },
  methods: {
    ...mapActions(["setOriginalCountryData"])
  },
  created() {
    this.setOriginalCountryData();
  }
};
</script>

<style scoped></style>
