<template>
  <div data-test="onboarding">
    <LegalInformation />
    <AdditionalInfo />
    <CustomsDocs />
  </div>
</template>
<script>
import AdditionalInfo from "./AdditionalInfo.vue";
import LegalInformation from "./LegalInformation.vue";
import CustomsDocs from "./CustomsDocs";
import countryFilter from "@/filters/countryFilter";
import router from "@/router";
import {mapActions} from "vuex";

export default {
  name: "Onboarding",
  components: {
    LegalInformation,
    AdditionalInfo,
    CustomsDocs
  },
  methods: {
    ...mapActions([
      "getOnboardingDetails"
    ]),
    async setInitialFormData(countryName) {
      const countryCode = countryFilter(countryName);
      if (countryCode) {
        await this.getOnboardingDetails(countryCode);
      }
    }
  },
  async created() {
    await this.setInitialFormData(router.currentRoute?.params?.countryName);
  }
};
</script>
