<template>
  <div id="chb-countries-overview">
    <div class="p-page__section theme--light" v-if="isLoading">
      <overlay-loading />
    </div>
    <ChbPageHeader />
    <ChbCountriesTable v-if="loggedInUser.isCHBAdmin"/>
    <NoAccess v-else/>
    <ChbModal v-if="modalIsVisible" />
  </div>
</template>

<script>
import ChbPageHeader from "../components/countriesOverviewPage/ChbPageHeader";
import ChbCountriesTable from "../components/countriesOverviewPage/ChbCountriesTable";
import NoAccess from "../components/countriesOverviewPage/NoAccess";
import ChbModal from "../components/modal/ChbModal";
import { mapState } from "vuex";
import { OverlayLoading } from "@maersk/vue-core";

// @ is an alias to /src
export default {
  name: "CountriesOverview",
  data() {
    return {
      loggedInUser: JSON.parse(window.localStorage.getItem("loggedInUserInfo"))
    };
  },
  components: {
    ChbPageHeader,
    ChbCountriesTable,
    NoAccess,
    ChbModal,
    OverlayLoading
  },
  computed: {
    ...mapState({
      modalIsVisible: state => state.modalController.isVisible,
      chbCountries: state => state.chbCountries,
      isLoading: state => state.isLoading
    })
  },
  created(){
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/settings";

#chb-countries-overview {
  padding-top: $chb-spacing-xsmall;
}
</style>
