<template>
  <div id="main" :class="[brand]" style="margin-top:0 !important">
    <router-view class="main_v3 ign_main"/>
  </div>
</template>
<script>
import applicationContext from "./ApplicationContext";

export default {
  name: "App",
  data: () => {
    return {
      brand: applicationContext.operator.scacCode
    };
  },
  created() {
    const elems = [
      document.getElementById("ign-header"),
      document.getElementById("ign-footer")
    ];
    for (const elem of elems) {
      if (elem) {
        elem.remove();
      }
    }
  }
};
</script>
<style lang="scss">
@import "scss/style";
.prevent-scroll {
  overflow: hidden;
}
#main {
  margin-bottom: 100px;
}
</style>
