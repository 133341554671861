<template>
  <ChbSection>
    <div class="tbl-header" id="table-header">
      <table>
        <thead>
          <tr>
            <td>{{ "chbAdmin.table.label.country" | i18n }}</td>
            <template v-for="brandObj in brandsMap">
              <td v-for="(n, index) in 2">
                {{ getTableLabelSitecoreKey(brandObj.brandCode, index) | i18n }}
              </td>
            </template>
          </tr>
        </thead>
      </table>
    </div>
    <div class="tbl-content">
      <table>
        <tbody>
          <tr v-for="country in countries" :key="country.countryCode">
            <td>
              <a
                @click="handleEditCountry($event, country)"
                href="javascript:void(0)"
                class="editCountry"
              >
                {{ country.countryName }}</a
              >
            </td>

            <template v-for="brandObj in brandsMap">
              <td v-for="(n, index) in 2">
                <span
                  class="icon"
                  :class="[
                    isBrandSupported(brandObj, country.configuration, index)
                      ? 'icon-tick'
                      : 'icon-close'
                  ]"
                ></span>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </ChbSection>
</template>

<script>
import ChbSection from "../common/ChbSection.vue";
import { mapState } from "vuex";
import { eventBus } from "../eventBus";
import { appUtilities } from "@/utilities/common";
import router from "@/router";

export default {
  name: "ChbCountriesTable",
  components: {
    ChbSection
  },
  data() {
    return {
      countries: [],
      brandsMap: [
        {
          brandCode: "MAEU",
          brandName: "maersk"
        },
        {
          brandCode: "SEAU",
          brandName: "sealand"
        },
        {
          brandCode: "TWIL",
          brandName: "twill"
        },
        {
          brandCode: "HSUD",
          brandName: "hSud"
        }
      ]
    };
  },
  computed: mapState(["chbCountries"]),
  methods: {
    handleEditCountry: async function(e, country) {
      e.preventDefault();
      router.push({
        name: "EditCountryPage",
        params: { countryName: country.countryName },
      });
    },
    isBrandSupported(brandObj, configuration, currentIndex) {
      const clearanceGroup =
        currentIndex % 2 === 0
          ? "importCustomsClearance"
          : "exportCustomsClearance";
      const countryBrandObj = configuration.find(
        brand => brand.brandCode === brandObj.brandCode
      );

      // added checks as exiting data had not values for twill and hSud
      if (countryBrandObj !== undefined) {
        return countryBrandObj.supportedProductGroups.includes(clearanceGroup);
      } else {
        return false;
      }
    },
    getTableLabelSitecoreKey(brandCode, currentIndex) {
      const group = currentIndex % 2 === 0 ? "Import" : "Export";
      const brandName = this.brandsMap.find(el => el.brandCode === brandCode)
        .brandName;
      return `chbAdmin.table.label.${brandName}${group}`;
    }
  },
  created() {
    if (this.chbCountries.length > 0) {
      this.countries = appUtilities.sortByAsc(this.chbCountries, "countryName");
    } else {
      this.countries = this.chbCountries;
    }
    eventBus.$on("sortCountries", data => {
      this.countries = data;
    });
  }
};
</script>

<style scoped lang="scss">
@import "../../scss/settings";

table {
  font-size: $chb-font-size;
  color: $body-font-color;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  tr {
    height: $chb-spacing-xxlarge;
  }

  td {
    line-height: 1.43;

    &:first-child {
      padding-left: $chb-spacing;
      text-align: left;
    }

    a {
      color: $chb-marine-blue;
      text-decoration: underline;
    }

    .icon-tick {
      color: $chb-success-green;
    }

    .icon-close {
      color: $chb-error-color;
    }
  }
}

.tbl-header {
  color: $body-font-color;
  background-color: $light-gray;
  border-radius: 3px 3px 0 0;
  border: 1px solid #cfcfcf;
  border-bottom-style: none;

  td {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }
}

.tbl-content {
  tr {
    border: solid 1px $border-color;
  }

  td {
    text-align: center;
    .editCountry {
      text-decoration: none;
      color: #0073ab;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
