<template>
  <dl>
    <dt class="title">{{ title }}</dt>
    <dd class="description"><slot></slot></dd>
  </dl>
</template>
<script>
export default {
  name: "ChbContentDetail",
  props: {
    title: {
      type: String,
      required: true
    }
  }
};
</script>
<style lang="scss" scoped>
.title {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #878787;
  white-space: nowrap;
}
.description {
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  padding-bottom: 8px;
  .check-color {
    color: #40ab35;
  }
  .wrong-color {
    color: #b80012;
  }
}
.services-on-container {
  display: flex;
  justify-content: start;
  flex-direction: row;
  gap: 10px;
  .shipment-mode-container {
    display: flex;
  }
  .shipment-text {
    padding-left: 8px;
  }
}
</style>