
export const operators = {
  maerskline: {
    scacCode: "MAEU",
    carrierCode: "MSL",
    displayName: "MAERSK LINE",
    domains: ["maerskline.com", "maersk.com"],
    domainPrefix: "maerskline"
  },
  safmarine: {
    scacCode: "SAFM",
    carrierCode: "SAF",
    displayName: "SAFMarine",
    domainPrefix: "safmarine",
    domains: ["safmarine.com"]
  },
  mcc: {
    scacCode: "MCPU",
    carrierCode: "MCC",
    displayName: "MCC Transport",
    domainPrefix: "mcc",
    domains: ["mcc.com.sg"]
  },
  seago: {
    scacCode: "SEJJ",
    carrierCode: "SGL",
    displayName: "Seago Line",
    domainPrefix: "seago",
    domains: ["seagoline.com"]
  },
  sealand: {
    scacCode: "SEAU",
    carrierCode: "SEA",
    displayName: "SEALAND",
    domainPrefix: "sealand",
    domains: ["sealand.com"]
  },

  localhost: {
    scacCode: "MAEU",
    carrierCode: "MSL",
    displayName: "MAERSK LINE",
    domains: ["localhost", "localhost"],
    domainPrefix: "maerskline"
  }

};

function getContentLocation(subdomain) {
  switch (subdomain) {
    case "apit":
    case "api-cdt":
    case "mybeta-dev":
    case "mybeta-cdt":
    case "myt":
    case "cdt":
      // cdt
      return "https://api-cdt.maersk.com";
    case "apid":
    case "stage":
    case "mybeta-stage":
    case "demo":
    case "my-demo":
      // pre-production
      return "https://api-stage.maersk.com";
    case "my":
    case "mybeta":
    case "beta":
    case "www":
      // production
      return "https://api.maersk.com";
    case "localhost":
      //return "http://localhost:8080";
      return "https://api-stage.maersk.com";
    default:
      return "https://api.maersk.com";
  }
}

export function getCurrentOperator() {
  const hostname = window.location.hostname;
  const operatorKeys = Object.keys(operators);
  for (let i = 0; i < operatorKeys.length; i++) {
    const operatorKey = operatorKeys[i];
    const operator = operators[operatorKey];

    if (hostname.toLowerCase().indexOf(operator.domainPrefix) >= 0) {
      return operator;
    }
  }
  return operators.maerskline;
}

const operator = getCurrentOperator();

/** Set operator(brand) for new IGN2 menu */
window.projectServices.brand = operator.scacCode.toLowerCase();

function getSubdomain() {
  return window.location.hostname.split(".")[0];
}

function getAssetsLocation(subdomain) {
  switch (subdomain) {
    case "apit":
    case "maerskline-int":
    case "maerskline-int4":
    case "maerskline-int5":
    case "myt":
    case "mybeta-dev":
    case "mybeta-cdt":
    case "cdt":
    case "localhost":
      return "//assetst.maerskline.com";
    case "demo":
    case "my-demo":
    case "myd":
    case "stage":
      return "//assetsd.maerskline.com";
    case "my":
    case "mybeta":
    case "beta":
    case "www":
      return "//assets.maerskline.com";
    default:
      return "//assets.maerskline.com";
  }
}

function getEnvironment(subdomain) {
  switch (subdomain) {
    case "apit":
    case "maerskline-int":
    case "maerskline-int4":
    case "maerskline-int5":
    case "myt":
    case "mybeta-dev":
    case "mybeta-cdt":
    case "cdt":
      return "cdt";
    case "localhost":
      return "demo";
    case "demo":
    case "my-demo":
    case "myd":
    case "stage":
      return "demo";
    case "my":
    case "mybeta":
    case "beta":
    case "www":
      return "prod";
    default:
      return "prod";
  }
}

export class ApplicationContext {
  constructor() {
    this.operator = operator;
    this.subdomain = getSubdomain();
    this.contentLocation = getContentLocation(this.subdomain);
    this.assetsLocation = getAssetsLocation(this.subdomain);
    this.env = getEnvironment(this.subdomain)
  }
}

export default new ApplicationContext();
