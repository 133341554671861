<template>
  <div :class="{ 'chb-error': !isValid }">
    <label>{{ label }}</label>
    <div class="chb-input">
      <span
        v-if="iconClass !== ''"
        :class="iconClass !== '' ? iconClass : ''"
        class="chb-input-icon"
      />
      <textarea
        autocomplete="off"
        :disabled="disabled"
        :name="name"
        :class="iconClass === '' ? 'chb-input-no-icon' : ''"
        :value="inputValue"
        :placeholder="!disabled ? placeholder : ''"
        @keyup="onChange"
        @blur="onBlur"
        @keydown.down.prevent=""
        @keydown.up.prevent=""
        maxlength="100"
      />
    </div>
    <p v-if="!isValid" class="chb-error-message">{{ errorText }}</p>
  </div>
</template>
<script>
export default {
  name: "ChbTextArea",
  props: {
    name: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: "" },
    inputValue: { default: "" },
    isValid: { type: Boolean, default: true },
    errorText: { type: String, default: "" },
    iconClass: { type: String, default: "" },
    placeholder: { type: String, default: "" },
  },
  methods: {
    onChange(e) {
      const fieldName = e.target.name;
      const fieldValue = e.target.value;

      this.$emit("onUpdate", { fieldName, fieldValue });
    },
    onBlur(e) {
      const fieldName = e.target.name;
      const fieldValue = e.target.value;

      this.$emit("onBlur", { fieldName, fieldValue: fieldValue.trim() });
    }
  }
};
</script>

<style lang="scss"></style>
