import store from "./store";
import countryFilter from "./filters/countryFilter";

export const handlers = {
  required(val) {
    let value = val;

    if (typeof value === "string") {
      value = value.trim();
    }

    return !(value === undefined || value === null || value === "");
  },
  regex(value, validator) {
    if (!value) {
      // do not validate undefined values, allow validation of non-required fields
      //return true;
      return false;
    }
    return validator.regex.test(String(value).toLowerCase());
  },
  phoneNumber(value) {
    return value
      ? this.regex(value, {
          regex: /^[+]?[(]?[0-9]{1,3}[)]?[-\s.]?[0-9]{1}[-\s.0-9]{3,10}$/
        })
      : false;
  },
  email(value) {
    return value
      ? this.regex(value, {
          regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([-a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/
        })
      : false;
  },
  notEmpty(arr) {
    return !!arr.length;
  },
  countryNotInTheTable (countryCode) {
    const { chbCountries } = store.state;
    if(chbCountries.length > 0 ){
      return !chbCountries.find(country => country.countryCode === countryFilter(countryCode))
    }
    else return true
  }
};
