<template>
  <div id="basic-country-info">
    <div class="form-currency-container">
      <div class="country-ino">
        <div class="label" :class="{ 'label-color-on-edit': isEditEnabled }">
          {{ "chbAdmin.editPage.form.editCountry.selectCurrency.label" | i18n }}
        </div>
        <div class="value" v-if="!isEditEnabled">
          {{ currencyCode.value }}
        </div>
        <ChbSelect
          name="currencyCode"
          :selectData="currencyCodeData"
          :selectedValue="country.currencyCode"
          :isValid="currencyCode.isValid"
          :errorText="currencyCode.errorText"
          @select-keyup="filterCurrencies"
          @select-change="onUpdate"
          :editable="true"
          v-else
        />
        <div class="label" :class="{ 'label-color-on-edit': isEditEnabled }">
          {{ "chbAdmin.editPage.form.editCountry.contactEmail.label" | i18n }}
        </div>
        <div class="value" v-if="!isEditEnabled">
          {{ countryContactEmail.value }}
        </div>
        <ChbInput
          name="countryContactEmail"
          :inputValue="country.countryContactEmail"
          :isValid="countryContactEmail.isValid"
          :errorText="countryContactEmail.errorText"
          @onUpdate="onUpdate"
          v-else
        />
        <div class="label" :class="{ 'label-color-on-edit': isEditEnabled }">
          {{ "chbAdmin.editPage.form.editCountry.contactPhone.label" | i18n }}
        </div>
        <div class="value" v-if="!isEditEnabled">
          {{ countryContactPhone.value }}
        </div>
        <ChbInput
          name="countryContactPhone"
          :inputValue="country.countryContactPhone"
          :isValid="countryContactPhone.isValid"
          :errorText="countryContactPhone.errorText"
          @onUpdate="onUpdate"
          v-else
        />
        <div class="label" :class="{ 'label-color-on-edit': isEditEnabled }">
          {{
            "chbAdmin.editPage.form.editCountry.documentUploadEmail.label"
              | i18n
          }}
        </div>
        <div class="value" v-if="!isEditEnabled">
          {{ countryUploadDocumentEmail.value }}
        </div>
        <ChbInput
          name="countryUploadDocumentEmail"
          :inputValue="country.countryUploadDocumentEmail"
          :isValid="countryUploadDocumentEmail.isValid"
          :errorText="countryUploadDocumentEmail.errorText"
          @onUpdate="onUpdate"
          v-else
        />
      </div>
      <div class="country-edit">
        <div class="edit-icon" v-if="!isEditEnabled">
          <i
            class="icon mi-24px mi-pencil pencil"
            aria-hidden="true"
            @click="editCountry"
          ></i>
        </div>
        <div class="theme--light actions" v-if="isEditEnabled">
          <a
            href="javascript:void(0)"
            role="button"
            class="button button--tertiary button--cancel"
            @click.prevent="handleCancel"
            :class="{ 'button--disabled': isFormInvalid }"
            >Cancel</a
          >
          <a
            href="javascript:void(0)"
            role="button"
            class="button button--primary button--submit"
            @click.prevent="saveCountry"
            :class="{ 'button--disabled': isFormInvalid }"
            >Finish
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChbSelect from "../../common/ChbSelect";
import ChbInput from "../../common/ChbInput.vue";
import currencyMap from "../../../resources/currencyMap.json";

import {mapState, mapActions, mapMutations} from "vuex";
import _ from "lodash";

export default {
  name: "ChbBasicCountryDataFields",
  components: {
    ChbSelect,
    ChbInput
  },
  data() {
    return {
      currencyCodeData: currencyMap.map(currency => currency.code),
      country: {},
      isFormInvalid: false
    };
  },
  computed: {
    ...mapState({
      countryName: state => state.editCountryController.formData.countryName,
      currencyCode: state => state.editCountryController.formData.currencyCode,
      countryContactEmail: state =>
        state.editCountryController.formData.countryContactEmail,
      countryContactPhone: state =>
        state.editCountryController.formData.countryContactPhone,
      countryUploadDocumentEmail: state =>
        state.editCountryController.formData.countryUploadDocumentEmail,
      isFormDataChanged: state => state.editCountryController.isFormDataChanged,
      isCurrencyCodeChanged: state =>
        state.editCountryController.isCurrencyCodeChanged,
      isEditEnabled: state => state.editCountryController.isEditEnabled,
      isFormValid: state => state.editCountryController.isFormValid,
      formValidators: state => state.editCountryController.validators,
      isNewCountry: state => state.editCountryController.isNewCountry,
      currentCountryState: state => {
        return {
          countryCode: state.editCountryController.formData.countryCode,
          countryContactEmail:
            state.editCountryController.formData.countryContactEmail,
          countryContactPhone:
            state.editCountryController.formData.countryContactPhone,
          countryName: state.editCountryController.formData.countryName,
          countryUploadDocumentEmail:
            state.editCountryController.formData.countryUploadDocumentEmail,
          currencyCode: state.editCountryController.formData.currencyCode
        };
      },
      originalCountryData: state => state.originalCountryData
    })
  },
  methods: {
    ...mapMutations([
      "updateCurrencyCode"
    ]),
    ...mapActions([
      "updateCountry",
      "setEditCountryFormDataChanged",
      "validateEditPageSubmit",
      "setIsEditEnabled",
      "setEditPageIsFormValid",
      "validateCountryForm",
      "enableAddProductButton"
    ]),
    async onUpdate({ fieldName, fieldValue }) {
      this.country[fieldName] = fieldValue;
      if (
        this.isFormInvalid ||
        (this.currentCountryState[fieldName] && this.currentCountryState[fieldName].value &&
          fieldName !== "currencyCode")
      ) {
        await this.validateCountryForm(this.country);
        this.isFormInvalid = !this.isFormValid;
      }
    },
    filterCurrencies({ searchValue, selectName }) {
      const result = currencyMap.filter(currency => {
        const currencyCode = currency.code.toLowerCase();
        const val = searchValue.toLowerCase();

        return currencyCode.startsWith(val);
      });

      const currentSelect = selectName + "Data";

      this[currentSelect] = result.map(currency => currency.code);
    },
    handleCancel() {
      this.setIsEditEnabled(false);
      this.updateProductButtonDisplay(this.currentCountryState);
      this.verifyFormDataHasChanged();
    },

    updateProductButtonDisplay() {
      this.enableAddProductButton(
        this.currentCountryState.countryContactEmail.value.length > 0 &&
        this.currentCountryState.countryContactPhone.value.length > 0 &&
        this.currentCountryState.countryUploadDocumentEmail.value.length > 0
      );
    },

    verifyFormDataHasChanged() {
      const isSameObject = _.isEqual(
        this.originalCountryData,
        this.currentCountryState
      );
      if (!isSameObject) {
        this.setEditCountryFormDataChanged(true);
      } else {
        this.setEditCountryFormDataChanged(false);
      }
    },
    async saveCountry() {
      await this.validateCountryForm(this.country);
      if (this.isFormValid) {
        this.updateCountry(this.country);
        this.setIsEditEnabled(false);
        this.verifyFormDataHasChanged();
        this.enableAddProductButton(true);
        this.isFormInvalid = false;
      } else {
        this.isFormInvalid = true;
      }
    },
    editCountry() {
      this.setIsEditEnabled(true);
      this.setInitialFormData();
    },
    setInitialFormData() {
      this.country["currencyCode"] = this.currencyCode.value;
      [
        "countryContactEmail",
        "countryContactPhone",
        "countryUploadDocumentEmail"
      ].forEach(item => {
        this.country[`${item}`] = this[`${item}`].value;
      });
    }
  },
  created() {
    this.setInitialFormData();
  }
};
</script>

<style lang="scss">
.form-currency-container {
  .chb-select {
    width: 65%;
    margin-bottom: 16px;
  }
  .chb-select-options {
    background: white;
  }
  .chb-input-icon {
    background: white;
  }
  .chb-error {
    .chb-input {
      margin-bottom: 0;
    }
  }
}
.actions {
  display: flex;
}
#basic-country-info {
  background: #f7f7f7;
  .form-currency-container {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-top: 24px;

    .value {
      margin-top: 8px;
      margin-bottom: 32px;
      color: #141414;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .label {
      color: #878787;
    }
    .label-color-on-edit {
      color: #141414;
    }
    .country-edit {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .edit-icon {
        flex: auto;
      }
      .pencil {
        display: flex;
        justify-content: flex-end;
        align-self: flex-start;
        color: #00243d;
        cursor: pointer;
      }
    }
  }
}
</style>
