<template>
  <section class="required-docs" data-test="required-docs">
    <chb-section class="required-docs--header theme--light">
      <h3 class="font--display-2" data-test="section-title">
        {{ labels.sectionTitle }}
      </h3>
      <mc-button
        icon="plus"
        appearance="neutral"
        variant="outlined"
        @click="addDocument()"
      >
        {{ labels.addDocument }}
      </mc-button>
    </chb-section>
    <chb-section class="required-docs--content">
      <draggable :list="documentList" :disabled="false">
        <div v-for="(item, index) in documentList" :key="index">
          <div
            class="required-docs--content_item"
            data-test="enrolment-request"
          >
            <div class="required-docs--content_item-move">
              <mc-icon size="24" icon="arrows-down-up"></mc-icon>
            </div>
            <div class="required-docs--content_item-name">{{ item.name }}</div>
            <div class="required-docs--content_item-actions">
              <mc-button
                hiddenlabel
                appearance="neutral"
                class="edit"
                variant="plain"
                icon="pencil"
                alt="Edit"
                fit="large"
                width="auto"
                @click="editDocument(index)"
              ></mc-button>
              <mc-button
                hiddenlabel
                appearance="neutral"
                class="delete"
                variant="plain"
                icon="trash"
                alt="Delete"
                fit="large"
                width="auto"
                @click="toggleDeleteModal(index)"
              ></mc-button>
            </div>
            <div class="required-docs--content_item-type">
              <div class="heading">{{ labels.type }}</div>
              <div class="content">{{ item.type.name }}</div>
            </div>
          </div>
        </div>
      </draggable>
    </chb-section>
    <customs-docs-modal
      v-if="showModal"
      :document="activeIndex !== null ? documentList[activeIndex] : null"
      @closing="toggleModal"
      @saveDocument="saveDocument"
    ></customs-docs-modal>
    <delete-modal
      v-if="showDeleteModal"
      dimension="small"
      @closing="toggleDeleteModal"
      @confirmDelete="deleteDocument()"
    >
      <span slot="heading">{{ labels.deleteModalHeading }}</span>
      <span slot="content">{{ labels.deleteModalContent }}</span>
      <span slot="cancel-button">{{ labels.cancel }}</span>
      <span slot="confirm-button">{{ labels.delete }}</span>
    </delete-modal>
  </section>
</template>
  
<script>
import ChbSection from "../../common/ChbSection.vue";
import i18nFilter from "../../../filters/i18nFilter";
import "@maersk-global/mds-components-core/mc-button";
import "@maersk-global/mds-components-core/mc-icon";
import CustomsDocsModal from "./modals/CustomsDocsModal.vue";
import DeleteModal from "./modals/DeleteModal.vue";
import draggable from "vuedraggable";
import { mapMutations, mapState } from "vuex";

export default {
  name: "CustomsDocs",
  components: {
    ChbSection,
    CustomsDocsModal,
    draggable,
    DeleteModal,
  },
  data() {
    return {
      showModal: false,
      showDeleteModal: false,
      activeIndex: null
    };
  },
  computed: {
    ...mapState({
      documentList: (state) => state.customDocs,
    }),
    labels() {
      const prefix =
        "chbAdmin.editPage.form.editCountry.onboarding.customsDocs";
      return {
        sectionTitle: i18nFilter(`${prefix}.sectionTitle`),
        addDocument: i18nFilter(`${prefix}.common.addDocument`),
        type: i18nFilter(`${prefix}.common.type`),
        deleteModalHeading: i18nFilter(`${prefix}.deleteModal.heading`),
        deleteModalContent: i18nFilter(`${prefix}.deleteModal.content`),
        cancel: i18nFilter(`chbAdmin.common.cancel`),
        delete: i18nFilter(`chbAdmin.common.delete`),
      };
    },
  },
  methods: {
    ...mapMutations(["updateCustomDocs"]),
    toggleModal() {
      this.showModal = !this.showModal;
    },
    toggleDeleteModal(index) {
      this.activeIndex = index;
      this.showDeleteModal = !this.showDeleteModal;
    },
    addDocument() {
      this.activeIndex = null;
      this.toggleModal();
    },
    editDocument(index) {
      this.activeIndex = index;
      this.toggleModal();
    },
    saveDocument(document) {
      let docList = [...this.documentList];
      if (this.activeIndex === null) {
        docList.push(document);
      } else {
        docList[this.activeIndex] = document;
      }
      this.updateCustomDocs(docList);
    },
    deleteDocument() {
      let docList = [...this.documentList];
      docList.splice(this.activeIndex, 1);
      this.updateCustomDocs(docList);
    },
  },
};
</script>
  
<style scoped lang="scss">
.required-docs {
  margin-top: 56px;
  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &--content {
    margin-top: 24px;
    &_item {
      cursor: grab;
      padding: 20px 24px;
      background: #f7f7f7;
      margin-bottom: 12px;
      display: grid;
      column-gap: 24px;
      grid-template-columns: 0.5fr 9fr 0.5fr;
      grid-template-areas:
        "move name actions"
        ".    type .      ";
      &-move {
        grid-area: move;
      }
      &-name {
        grid-area: name;
        font-size: 20px;
      }
      &-actions {
        grid-area: actions;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: "edit delete";
        column-gap: 24px;
        align-items: center;
        .edit {
          grid-area: edit;
        }
        .delete {
          grid-area: delete;
        }
        .edit,
        .delete {
          cursor: pointer;
          margin: 0px auto;
        }
      }
      &-type {
        grid-area: type;
        margin-top: 20px;
        .heading {
          font-size: 14px;
          color: var(--Functional-Grey-600, #878787);
        }
        .content {
          font-size: 16px;
          color: var(--Functional-Grey-800, #353535);
        }
      }
    }
  }
}
</style>
  