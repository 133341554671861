<template>
  <div v-clickoutside="closeSelect">
    <label class="chb-input-label" v-if="label && label.length">{{
      label
    }}</label>
    <div :class="{ 'chb-error': !isValid }" class="chb-select">
      <div class="chb-select">
        <div class="chb-input">
          <span
            v-if="showIcon"
            :class="selectIcon"
            class="chb-input-icon"
            @mousedown.prevent="toggleSelect"
          />
          <input
            :name="name"
            :disabled="disabled"
            autocomplete="off"
            ref="input"
            :class="showIconClass"
            :placeholder="!disabled ? placeholder : ''"
            :aria-label="label"
            :readonly="!editable"
            :value="selectedValueText"
            type="text"
            @focus="showSelect"
            @keyup.esc="closeSelect"
            @keydown.up.prevent="typeAheadUp"
            @keydown.down.prevent="typeAheadDown"
            @keydown.enter.prevent="typeAheadSelect"
            @blur="closeSelect"
            @keyup="onUserInput"
            @mousedown="onInputClick"
          />
          <span
            v-if="!disabled"
            :class="{
              'icon-arrow-dropdown': !selectExpanded,
              'icon-arrow-dropup': selectExpanded
            }"
            class="chb-input-icon"
            @mousedown.prevent="toggleSelect"
          />
        </div>
        <div v-if="!disabled" class="chb-custom-dropdown">
          <slot name="custom-dropdown">
            <ul
              tabindex="-1"
              @mousemove="setScrollBarActive"
              @mouseleave="scrollbarActive = false"
              :class="{ 'chb-select-active': selectExpanded }"
              class="chb-select-options"
              :style="{ height: getListHeight() }"
            >
              <li
                v-for="(option, index) in additionalOptions"
                :key="index"
                class="chb-select-option chb-select-option-add"
                @mouseover="typeAheadPointer = index"
                @mousedown.prevent="
                  onAdditionalItemClick(index);
                  closeSelect();
                "
              >
                <span
                  :class="{
                    'chb-button-link-highlight': index === typeAheadPointer
                  }"
                  class="chb-button-link"
                  >{{ option.labelKey | i18n }}</span
                >
              </li>
              <li
                v-for="(item, index) in selectData"
                :key="item.id"
                :class="{
                  'chb-select-option-highlight':
                    additionalOptions.length + index === typeAheadPointer
                }"
                class="chb-select-option"
                @mouseover="typeAheadPointer = index"
                @mousedown.prevent="selectItem(item)"
              >
                <span>{{ itemFormat(item) }}</span>
              </li>
              <li v-if="selectData.length === 0" class="chb-select-option">
                <span>{{ "chbAdmin.form.select.noMatch.message" | i18n }}</span>
              </li>
            </ul>
          </slot>
        </div>
        <p v-if="!isValid" class="chb-error-message">{{ errorText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { setStickyHeader } from "@/utilities";
export default {
  name: "ChbSelect",
  props: {
    name: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    isValid: { type: Boolean, default: true },
    errorText: { type: String, default: "" },
    label: { type: String, default: "" },
    showLabel: { type: Boolean, default: true },
    editable: { type: Boolean, default: false },
    selectedValue: { type: [String, Object], default: null },
    selectData: { type: Array, default: () => [] },
    dataFormat: { type: Object, default: () => ({}) },
    additionalOptions: { type: Array, default: () => [] },
    placeholder: { type: String, default: "" },
    selectIcon: { type: String, default: "icon-place" },
    showIcon: { type: Boolean, default: false },
    disableArrow: { type: Boolean, default: false },
    showSlotDropdown: { type: Boolean, default: false }
  },
  data() {
    return {
      scrollbarActive: false,
      selectExpanded: false,
      userInput: null,
      typeAheadPointer: 0
    };
  },
  computed: {
    selectedValueText: {
      get() {
        // if editable and there is custom input display it
        if (this.editable && this.userInput != null && this.userInput != "") {
          return this.userInput;
        }

        // else just show selected value using item format
        return this.selectedValue !== undefined && this.selectedValue !== null
          ? this.itemFormat(this.selectedValue)
          : "";
      }
    },
    showIconClass: function() {
      return !this.showIcon ? "chb-input-no-icon" : "";
    }
  },
  methods: {
    setScrollBarActive(e) {
      if (e.target.clientWidth < e.x) {
        this.scrollbarActive = true;
      } else {
        this.scrollbarActive = false;
      }
    },
    showSelect() {
      this.selectExpanded = true;
    },
    getListHeight() {
      const listEntries = this.selectData.length || 1;
      if (listEntries > 5) {
        return "220px";
      } else {
        // return listEntries * 44 + 'px';
        return listEntries * 40 + "px";
      }
    },
    closeSelect(selectedItem) {
      const isIE =
        !!navigator.userAgent.match(/Trident/g) ||
        !!navigator.userAgent.match(/MSIE/g);
      if (isIE) {
        if (!this.scrollbarActive) {
          this.selectExpanded = false;
        }
      } else {
        this.selectExpanded = false;
      }
      if (selectedItem && selectedItem.id) {
        this.typeAheadPointer = this.selectData.findIndex(
          item => item.id === selectedItem.id
        );
      } else {
        this.userInput = "";
        this.$emit("select-keyup", {
          selectName: this.name,
          searchValue: ""
        });
      }
    },
    toggleSelect() {
      if (this.selectExpanded) {
        this.closeSelect();
      } else {
        setStickyHeader();
        this.showSelect();
      }
    },
    onInputClick() {
      if (this.editable) {
        // for editable input disallow toggle when user clicks on input
        this.showSelect();
      } else {
        // otherwise mimic standard select field
        this.toggleSelect();
      }
    },
    onUserInput(e) {
      if (
        e.key === "Escape" ||
        e.key === "Enter" ||
        e.key === "Tab" ||
        e.key === "Shift"
      ) {
        return;
      }
      this.showSelect();

      this.userInput = e.target.value;

      this.$emit("select-keyup", {
        selectName: this.name,
        searchValue: this.userInput
      });
      this.$emit("select-change", { fieldName: this.name, fieldValue: "" });
    },
    selectItem(item) {
      this.userInput = item;
      this.$emit("select-change", { fieldName: this.name, fieldValue: item });
      this.closeSelect(item);
    },
    itemFormat(item) {
      return this.dataFormat.formatItem !== undefined
        ? this.dataFormat.formatItem(item)
        : item;
    },
    typeAheadUp() {
      if (this.typeAheadPointer > 0 && this.selectExpanded) {
        this.typeAheadPointer--;
      }
    },
    typeAheadDown() {
      if (
        this.typeAheadPointer <
          this.additionalOptions.length + this.selectData.length - 1 &&
        this.selectExpanded
      ) {
        this.typeAheadPointer++;
      }
    },
    typeAheadSelect() {
      if (
        this.selectData[this.typeAheadPointer - this.additionalOptions.length]
      ) {
        this.selectItem(
          this.selectData[this.typeAheadPointer - this.additionalOptions.length]
        );
      } else if (this.additionalOptions[this.typeAheadPointer]) {
        this.onAdditionalItemClick(this.typeAheadPointer);
        this.closeSelect();
      }
    },
    onAdditionalItemClick(index) {
      this.additionalOptions[index].onClick();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/settings";

.chb-select-options {
  overflow-y: scroll;
  border: 1px solid $border-color;
}
.chb-input-icon {
  display: flex;
  align-items: center;
  padding-right: 8px;
}
</style>
