window.onscroll = setStickyHeader;

export function setStickyHeader() {
  let header = document.getElementById("chb-header");
  if (header) {
    let tabHeader = document.getElementById("table-header");
    let dropDown = document.getElementsByClassName("chb-select-options");
    let sticky = header.offsetTop;
    if (window.pageYOffset > sticky) {
      header.classList.add("sticky", "sticky-chb-header");
      dropDown[0].style.top = '-80px';
      tabHeader.classList.add("sticky", "sticky-table-header");
    } else {
      header.classList.remove("sticky", "sticky-chb-header");
      tabHeader.classList.remove("sticky", "sticky-table-header");
      dropDown[0].style.top = '40px';
    }
  }
}
