import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";
import countryFilter from "./filters/countryFilter";
import CountriesOverview from "./views/CountriesOverview.vue";
import EditCountryPage from "./views/EditCountryPage";
import Error404Page from "./views/Error404Page";
import Login from "./views/Login";
import { setIDToken, setUserInfoToLocalStorage, isUserLoggedIn } from "./authentication";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/admin",
      name: "CountriesOverview",
      component: CountriesOverview,
      beforeEnter: async (to, from, next) => {
        setIDToken();
        if(isUserLoggedIn()){
          setUserInfoToLocalStorage();
          await store.dispatch("closeModal");
          if (JSON.parse(window.localStorage.getItem("loggedInUserInfo")).isCHBAdmin) {
            await store.dispatch("getCountries");
          } 
          next();
          window.location.hash = '';
        } else {
          next("/admin/login");
        }
      }
    },
    {
      path: "/admin/login",
      name: "Login",
      component: Login,
      beforeEnter: async (to, from, next) => {
        if (!isUserLoggedIn()) {
          next();
        } else {
          next("/admin/");
        }
      },
    },
    {
      path: "/admin/edit/:countryName/products",
      name: "EditCountryPage",
      component: EditCountryPage,
      beforeEnter: async (to, from, next) => {
        if (isUserLoggedIn()) {
          if (!store.state.editCountryController.isNewCountry) {
            const countryCode = countryFilter(to.params.countryName);
            await store.dispatch("setCountryData", { countryCode });
          }
          next();
        } else {
          next("/admin/login");
        }
      }
    },
    {
      path: "/admin/edit/:countryName/onboarding",
      name: "Onboarding",
      component: EditCountryPage,
      beforeEnter: async (to, from, next) => {
        if (isUserLoggedIn()) {
          if (!store.state.editCountryController.isNewCountry) {
            const countryCode = countryFilter(to.params.countryName);
            await store.dispatch("setCountryData", { countryCode });
          }
          next();
        } else {
          next("/admin/login");
        }
      }
    },
    {
      path: "/admin/error404",
      name: "Error404Page",
      component: Error404Page,
      beforeEnter: async (to, from, next) => {
        if (store.state.showErrorPage) {
          next();
        } else {
          next("/admin");
        }
      }
    },
    {
      path: "*",
      redirect: "/admin/error404"
    }
  ],
  scrollBehavior: () => ({ x: 0, y: 0 })
});

export default router;
