import Vue from "vue";
import { constants } from "./CONSTANTS";

/**
 * @description Checks if feature is enabled Locally or in LD
 * @param {String} feature
 */
export function isFeatureEnabled(feature) {
  const local = getFeatureValueForTesting(feature);
  const ld = getFeatureValueFromLD(feature);
  return local || ld;
}

/**
 * Gets the value of the feature flag for testing from Local Storage
 * @param {string} feature
 */
function getFeatureValueForTesting(feature) {
  const featureValue = localStorage.getItem(
    `${constants.LD_PREFIX}-${feature}`
  );
  return featureValue ? JSON.parse(featureValue) : false;
}

/**
 * @description Gets the fallback value set in constants
 * @param {String} feature
 */
function getFallback(feature) {
  return !!constants.featureFlags[feature].value;
}

/**
 * @description Gets feature value from LD, if not available then gets the fallback value
 * @param {String} feature
 */
function getFeatureValueFromLD(feature) {
  if (
    Vue.prototype?.$featureFlags?.ready &&
    constants.featureFlags[feature] !== undefined
  ) {
    return Vue.prototype.$featureFlags.getFlag(feature, getFallback(feature))
      .value;
  }
  return getFallback(feature);
}
