<template>
  <div id="onboarding-legal-info">
    <div class="form-container">
      <div>
        <div class="label" :class="{ 'label-color-on-edit': isEditEnabled }">
          {{ labels.name }}
        </div>
        <div class="value" v-if="!isEditEnabled">
          {{ legalEntityData.name }}
        </div>
        <ChbInput
          v-else
          name="name"
          :inputValue="legalEntityData.name"
          @onUpdate="onUpdate"
        />
        <div class="label" :class="{ 'label-color-on-edit': isEditEnabled }">
          {{ labels.address }}
        </div>
        <div class="value" v-if="!isEditEnabled">
          {{ legalEntityData.address }}
        </div>
        <ChbTextArea
          v-else
          name="address"
          :inputValue="legalEntityData.address"
          @onUpdate="onUpdate"
        />
      </div>
      <div class="legal-entity-edit">
        <div class="edit-icon" v-if="!isEditEnabled">
          <i
            class="icon mi-24px mi-pencil pencil"
            aria-hidden="true"
            @click="editLegalEntity"
          ></i>
        </div>
        <div class="theme--light actions" v-if="isEditEnabled">
          <button
            href="javascript:void(0)"
            role="button"
            class="button button--tertiary button--cancel"
            @click.prevent="handleCancel"
          >
            {{ labels.cancel }}
          </button>
          <button
            href="javascript:void(0)"
            role="button"
            class="button button--primary button--submit"
            @click.prevent="onSaveLegalEntity"
          >
            {{ labels.finish }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChbTextArea from "@/components/common/ChbTextArea";
import ChbInput from "@/components/common/ChbInput";
import { mapMutations, mapState } from "vuex";
import i18nFilter from "@/filters/i18nFilter";

export default {
  name: "LegalInformation",
  components: {
    ChbTextArea,
    ChbInput
  },
  data() {
    return {
      isEditEnabled: false,
      legalEntityData: {
        name: "",
        address: ""
      }
    };
  },
  computed: {
    ...mapState({
      legalEntity: state => state.legalEntity.data,
    }),
    labels() {
      const prefix = {
        legalEntity: "chbAdmin.editPage.form.editCountry.onboarding.legalInformation",
        common: "chbAdmin.common"
      }
      return {
        name: i18nFilter(`${prefix.legalEntity}.name`),
        address: i18nFilter(`${prefix.legalEntity}.address`),
        cancel: i18nFilter(`${prefix.common}.cancel`),
        finish: i18nFilter(`${prefix.common}.finish`)
      };
    }
  },
  methods: {
    ...mapMutations(["updateLegalEntity"]),
    onUpdate({ fieldName, fieldValue }) {
      this.legalEntityData[fieldName] = fieldValue;
    },
    async onSaveLegalEntity() {
      this.isEditEnabled = false;
      const data = {
        entityName: this.legalEntityData.name,
        entityAddress: this.legalEntityData.address
      };
      this.updateLegalEntity(data);
    },
    handleCancel() {
      this.isEditEnabled = false;
      this.setLegalEntityData();
    },
    editLegalEntity() {
      this.isEditEnabled = true;
    },
    setLegalEntityData() {
      this.legalEntityData.name = this.legalEntity.name;
      this.legalEntityData.address = this.legalEntity.address;
    }
  },
  watch: {
    legalEntity: {
      handler() {
        this.setLegalEntityData();
      },
      deep: true
    }
  },
  mounted() {
    this.setLegalEntityData();
  }
};
</script>

<style lang="scss">
.actions {
  display: flex;
}
#onboarding-legal-info {
  background: #f7f7f7;
  .form-container {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-top: 24px;

    .value {
      margin-top: 8px;
      margin-bottom: 32px;
      color: #141414;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .label {
      color: #878787;
    }
    .label-color-on-edit {
      color: #141414;
    }
    .legal-entity-edit {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .edit-icon {
        flex: auto;
      }
      .pencil {
        display: flex;
        justify-content: flex-end;
        align-self: flex-start;
        color: #00243d;
        cursor: pointer;
      }
    }
  }
}
</style>
