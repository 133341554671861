<template>
  <ChbSection>
    <div class="title">
      <h3 class="font--display-2">
        {{
          "chbAdmin.editPage.form.editCountry.productsFormTable.importProducts.label"
            | i18n
        }}
      </h3>
      <div class="theme--light">
        <a
          href="javascript:void(0)"
          role="button"
          class="button button--icon--left button--tertiary"
          @click.prevent="handleAddProduct('importCustomsClearance')"
          :class="{ 'button--disabled': isEditEnabled || !isAddProductEnabled }"
        >
          {{
            "chbAdmin.editPage.form.editCountry.productFormTable.button.importCustomsClearance.addProduct"
              | i18n
          }}
          <i class="icon button__icon mi-20px mi-plus" aria-hidden="true"></i>
        </a>
      </div>
    </div>
    <div>
      <draggable :list="importProducts" :disabled="false" :move="checkMove">
        <ProductDetails
          v-for="product in importProducts"
          :key="product.productId"
          :product="product"
          :importProducts="importProducts"
          productGroup="importCustomsClearance"
          @verifyFormDataChanged="verifyFormDataChanged()"
        />
      </draggable>
    </div>
    <div class="title">
      <h3 class="font--display-2">
        {{
          "chbAdmin.editPage.form.editCountry.productsFormTable.exportProducts.label"
            | i18n
        }}
      </h3>
      <div class="theme--light">
        <a
          href="javascript:void(0)"
          role="button"
          class="button button--icon--left button--tertiary"
          @click.prevent="handleAddProduct('exportCustomsClearance')"
          :class="{ 'button--disabled': isEditEnabled || !isAddProductEnabled }"
        >
          {{
            "chbAdmin.editPage.form.editCountry.productFormTable.button.exportCustomsClearance.addProduct"
              | i18n
          }}
          <i class="icon button__icon mi-20px mi-plus" aria-hidden="true"></i>
        </a>
      </div>
    </div>
    <div>
      <draggable :list="exportProducts" :disabled="false" :move="checkMove">
        <ProductDetails
          v-for="product in exportProducts"
          :key="product.productId"
          :product="product"
          productGroup="exportCustomsClearance"
          :defaultProduct="defaultProduct"
          :exportProducts="exportProducts"
          @verifyFormDataChanged="verifyFormDataChanged()"
        />
      </draggable>
    </div>
  </ChbSection>
</template>

<script>
import ChbSection from "../../common/ChbSection";
import ProductDetails from "./chbProductDetails";
import { createProductFormObject } from "@/formUtilities";
import { mapState, mapActions, mapMutations } from "vuex";
import i18nFilter from "../../../filters/i18nFilter";
import draggable from "vuedraggable";
import _ from "lodash";

export default {
  name: "ChbProductsForm",
  components: {
    ChbSection,
    ProductDetails,
    draggable
  },
  data() {
    return {
      dragging: false,
      importDataList: [],
      importProducts: [],
      exportProducts: [],
      defaultProduct: null
    };
  },
  computed: {
    ...mapState({
      products: state =>
        state.editCountryController.formData.productGroups.value,
      isEditEnabled: state => state.editCountryController.isEditEnabled,
      isAddProductEnabled: state => state.isAddProductEnabled,
      formData: state => state.editCountryController.formData,
      originalCountryProductsData: state => state.originalCountryProductsData
    })
  },
  async created() {
    this.importProducts = this.getProductGroup("importCustomsClearance");
    this.exportProducts = this.getProductGroup("exportCustomsClearance");
    await this.setDefaultProduct();
  },
  methods: {
    ...mapActions(["openModal", "setEditCountryFormDataChanged"]),
    ...mapMutations(["editExistingProduct"]),
    getProductGroup(productGroupName) {
      return this.products.find(el => el.productGroupId === productGroupName)
        .products;
    },
    checkMove(e) {
      this.setEditCountryFormDataChanged(true);
    },
    handleAddProduct: function(productGroupName) {
      const modalOptions = {
        contentComponent: "ChbConfigureProductForm",
        data: {
          productGroup: productGroupName,
          isNewProduct: true,
          isFormValidated: false,
          isFormValid: true,
          formFields: createProductFormObject(),
          formValidators: {
            productName: ["required"],
            productDescription: ["required"],
            supportedShipmentServiceModes: ["notEmpty"]
          },
          formError: {
            isVisible: false,
            errorText: ""
          }
        },
        headerText:
          productGroupName === "importCustomsClearance"
            ? i18nFilter("chbAdmin.modal.addProduct.import.header.label")
            : i18nFilter("chbAdmin.modal.addProduct.export.header.label"),
        submitButtonOptions: {
          isDisabled: false,
          label: i18nFilter("chbAdmin.modal.addProduct.submit.button.label")
        },
        deleteButtonOptions: {
          isVisible: true,
          isDisabled: true,
          label: i18nFilter("chbAdmin.modal.addProduct.delete.button.label")
        }
      };
      this.openModal(modalOptions);
    },
    async setDefaultProduct() {
      const defaultProduct = this.exportProducts.filter(
        product => product.defaultProduct
      );
      if (defaultProduct.length) {
        this.defaultProduct = defaultProduct[0];
      } else if (this.exportProducts.length === 1) {
        this.defaultProduct = {
          ...this.exportProducts[0],
          defaultProduct: true
        };
        await this.editExistingProduct({
          productGroup: "exportCustomsClearance",
          product: this.defaultProduct
        });
        this.verifyFormDataChanged();
      } else {
        this.defaultProduct = null;
      }
    },
    verifyFormDataChanged() {
      const isSameObject = _.isEqual(
        this.originalCountryProductsData,
        this.formData.productGroups
      );
      if (!isSameObject) {
        this.setEditCountryFormDataChanged(true);
      } else {
        this.setEditCountryFormDataChanged(false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  margin-top: 56px;
  display: flex;
  justify-content: space-between;
}
</style>
