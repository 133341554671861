import {
  deleteCountry,
  getCountries,
  getCountry,
  save
} from "@/services/ChbCountriesService";
import { fetchOnboardingData } from "@/services/OnboardingService";
import logger from "../logger";
import validateForm from "../validationUtilities";
import {
  createCountryFormObject,
  extractDataFromForm,
  extractCountryDataFromForm,
  getMappedChargeType,
  generateUUID
} from "@/formUtilities";

import i18nFilter from "../filters/i18nFilter";
import _ from "lodash";
import router from "../router";

const actions = {
  async getCountries({ state, commit }) {
    try {
      let chbCountries = state.chbCountries;
      if (!chbCountries.length) {
        commit("setLoaderState", true);
        chbCountries = await getCountries();
      }
      commit("updateChbCountries", chbCountries);
      commit("showErrorPage", false);
    } catch (e) {
      commit("updateChbCountries", []);
      commit("showErrorPage", true);
    }
  },
  openModal({ commit }, modalOptions) {
    // If contentComponent is not provided we are not opening the modal
    if (!modalOptions.contentComponent) {
      logger.error("Can't open modal without provided content component name");
      return;
    }
    // Destructuring options and applying default value if none was provided
    const {
      contentComponent,
      data = null,
      headerText = "",
      modalWidth = "640px",
      submitButtonOptions = {
        isDisabled: false,
        label: i18nFilter("chbAdmin.modal.button.submit.label.default")
      },
      cancelButtonOptions = {
        isVisible: true,
        isDisabled: false,
        label: i18nFilter("chbAdmin.modal.button.cancel.label.default")
      },
      deleteButtonOptions = {
        isVisible: false,
        isDisabled: false,
        label: ""
      }
    } = modalOptions;
    commit("setModalVisibility", true);
    commit("setModalContentComponent", contentComponent);
    commit("setModalData", data);
    commit("setModalHeaderText", headerText);
    commit("setModalWidth", modalWidth);
    commit("setModalSubmitButtonIsDisabled", submitButtonOptions.isDisabled);
    commit("setModalSubmitButtonLabel", submitButtonOptions.label);
    commit("setModalCancelButtonVisibility", cancelButtonOptions.isVisible);
    commit("setModalCancelButtonIsDisabled", submitButtonOptions.isDisabled);
    commit("setModalCancelButtonLabel", cancelButtonOptions.label);
    commit("setModalDeleteButtonVisibility", deleteButtonOptions.isVisible);
    commit("setModalDeleteButtonIsDisabled", deleteButtonOptions.isDisabled);
    commit("setModalDeleteButtonLabel", deleteButtonOptions.label);
  },
  closeModal({ commit }) {
    commit("setModalVisibility", false);
    commit("setModalContentComponent", "");
    commit("setModalData", null);
    commit("setModalHeaderText", "");
    commit("setModalWidth", "0px");
    commit("setModalSubmitButtonLabel", "");
    commit("setModalSubmitButtonIsDisabled", false);
    commit("setModalCancelButtonVisibility", false);
    commit("setModalCancelButtonIsDisabled", false);
    commit("setModalCancelButtonLabel", "");
    commit("setModalDeleteButtonVisibility", false);
    commit("setModalDeleteButtonIsDisabled", false);
    commit("setModalDeleteButtonLabel", "");
  },
  updateModalAddCountryData({ state, commit }, { fieldName, fieldValue }) {
    const {
      isFormValidated,
      formFields,
      formValidators
    } = state.modalController.data;
    let isFormValid = false;
    commit("updateModalAddCountryData", { fieldName, fieldValue });
    if (isFormValidated) {
      isFormValid = validateForm(
        formValidators,
        formFields,
        "addCountry",
        ({ prop, isValid, errorText }) => {
          commit("setFormErrorFields", { prop, isValid, errorText });
        }
      );
      commit("setIsFormValid", isFormValid);
      commit("setModalSubmitButtonIsDisabled", !isFormValid);
    }
  },
  setEditPageData({ commit }, country) {
    const countryFormData = createCountryFormObject(country);
    commit("setEditCountryFormData", countryFormData);
  },

  setIsEditEnabled({ commit }, isEnabled) {
    commit("setIsEditEnabled", isEnabled);
  },
  async setupEditPage({ commit, dispatch }, country) {
    // Deep cloning country object so we don't accidentally change original object
    await dispatch("setEditPageData", _.cloneDeep(country));
    await dispatch("closeModal");
    if (country.isNewCountry) {
      commit("setIsNewCountry", true);
    } else {
      commit("setIsNewCountry", false);
    }
  },
  validateAddCountryForm({ state, commit }) {
    const {
      isFormValidated,
      formFields,
      formValidators
    } = state.modalController.data;
    let isFormValid = false;
    if (!isFormValidated) {
      isFormValid = validateForm(
        formValidators,
        formFields,
        "addCountry",
        ({ prop, isValid, errorText }) => {
          commit("setFormErrorFields", { prop, isValid, errorText });
        }
      );
      commit("setIsFormValidated", true);
      commit("setIsFormValid", isFormValid);
      commit("setModalSubmitButtonIsDisabled", !isFormValid);
    }
  },
  async setCountryData({ state, commit, dispatch }, country) {
    try {
      if (country.isNewCountry) {
        const { data } = state.modalController;
        if (data) {
          await dispatch("validateAddCountryForm");
          if (!data.isFormValid) {
            return;
          }
        }
      } else {
        await dispatch("setLoaderState", true);
        country = await getCountry(country.countryCode);
      }
      await dispatch("setupEditPage", country);
      await dispatch("setIsEditEnabled", country.isEditEnabled);
      await dispatch("enableAddProductButton", true);
    } catch (error) {
      await commit("showErrorPage", true);
    }
  },
  openLeavingWithoutSavingModal({ dispatch }) {
    const modalOptions = {
      headerText: i18nFilter(
        "chbAdmin.editPage.modal.leaveWithoutSavingError.title"
      ),
      contentComponent: "ChbLeaveWithoutSavingError",
      submitButtonOptions: {
        label: i18nFilter(
          "chbAdmin.editPage.modal.leaveWithoutSavingError.button.submit.label"
        )
      },
      cancelButtonOptions: {
        label: i18nFilter(
          "chbAdmin.editPage.modal.leaveWithoutSavingError.button.cancel.label"
        ),
        isVisible: true,
        isDisabled: false
      }
    };
    dispatch("openModal", modalOptions);
  },

  updateCountry({ commit }, country) {
    commit("updateCountry", country);
  },

  setEditPageIsFormValid({ commit }, isFormValid) {
    commit("setEditPageIsFormValid", isFormValid);
  },

  resetEditPageController({ commit }) {
    commit("setEditCountryFormData", null);
    commit("setIsNewCountry", false);
    commit("setEditCountryIsFormValidated", false);
    commit("setEditCountryFormDataChanged", false);
    commit("setEditCountryIsFormValid", true);
    commit("setEditPageIsFormValid", false);
  },

  setEditCountryFormDataChanged({ commit }, isFormDataChanged) {
    commit("setEditCountryFormDataChanged", isFormDataChanged);
  },

  goToCountriesOverviewPage() {
    router.push({
      name: "CountriesOverview"
    });
  },
  async deleteCurrentCountry({ commit, dispatch, state }) {
    const currentCountryCode =
      state.editCountryController.formData.countryCode.value;
    try {
      await deleteCountry(currentCountryCode);
      await commit("deleteCountryInCountries", currentCountryCode);
      await dispatch("goToCountriesOverviewPage");
    } catch (e) {
      alert("Unable to delete country, please try again later!");
    }
  },
  async addCurrentCountry({ commit, dispatch, state }) {
    const { formData, isFormValid } = state.editCountryController;
    if (!isFormValid) {
      return;
    }
    const currentCountryData = extractCountryDataFromForm(formData);
    const listViewCountryData = {
      countryName: currentCountryData.countryName,
      configuration: currentCountryData.configuration,
      countryCode: currentCountryData.countryCode
    };
    try {
      await save(currentCountryData);
      commit("addNewCountry", listViewCountryData);
      await dispatch("goToCountriesOverviewPage");
    } catch (e) {
      alert("Unable to add country, please try again later!");
    }
  },
  async editCurrentCountry({ commit, dispatch, state }) {
    const { formData, isFormValid } = state.editCountryController;
    if (!isFormValid) {
      return;
    }
    const currentCountryData = extractCountryDataFromForm(formData);
    const exportProducts = currentCountryData.productGroups.find(
      el => el.productGroupId === "exportCustomsClearance"
    ).products;
    const defaultProduct = exportProducts?.filter(
      product => product.defaultProduct
    );
    const checkingCondition = exportProducts?.length
      ? defaultProduct.length
      : true;
    try {
      if (checkingCondition) {
        await save(currentCountryData);
        commit("updateCountryInCountries", currentCountryData);
        await dispatch("goToCountriesOverviewPage");
      } else {
        alert("You need to choose a default product before you can save.");
        commit("setLoaderState", false);
      }
    } catch (e) {
      alert("Unable to edit country, please try again later!");
    }
  },
  async getOnboardingDetails({ commit }, countryCode) {
    commit("resetOnboardingData");
    try {
      const data = await fetchOnboardingData(countryCode);
      if(data.legalInformation) {
        commit("updateLegalEntity", data.legalInformation);
      }
      if(data.additionalInformation) {
        commit("updateAdditionalInformation", data.additionalInformation);
      }
      if(data.requiredDocuments.length) {
        commit("updateCustomDocs", data.requiredDocuments);
      }
    } catch (e) {
      commit("updateLegalEntity", {});
      commit("showErrorPage", true);
    }
  },
  validateCountryForm({ commit, state }, country) {
    const formData = createCountryFormObject(country);
    const { validators } = state.editCountryController;
    let isFormValid = false;
    isFormValid = validateForm(
      validators,
      formData,
      "editPage",
      ({ prop, isValid, errorText }) => {
        commit("setEditPageFormErrorFields", { prop, isValid, errorText });
      }
    );
    commit("setEditPageIsFormValid", isFormValid);
  },
  validateEditPageSubmit({ commit, state }) {
    const {
      formData,
      isFormValidated,
      validators
    } = state.editCountryController;
    let isFormValid = false;
    if (!isFormValidated) {
      isFormValid = validateForm(
        validators,
        formData,
        "editPage",
        ({ prop, isValid, errorText }) => {
          commit("setEditPageFormErrorFields", { prop, isValid, errorText });
        }
      );
      commit("setEditPageIsFormValidated", true);
      commit("setEditPageIsFormValid", true);
    } else {
      return;
    }
    if (!isFormValid) {
      commit("setEditPageIsFormValid", false);
    }
  },
  updateModalConfigureProductFormData(
    { state, commit },
    { fieldName, fieldValue }
  ) {
    const {
      isFormValidated,
      formFields,
      formValidators
    } = state.modalController.data;
    let indexOfChargeLine, formatedFieldName, formatedFieldValue, isFormValid;
    if (fieldName.includes("unitPrice") || fieldName.includes("chargeUnit")) {
      formatedFieldName = fieldName.includes("unitPrice")
        ? "unitPrice"
        : "chargeUnit";
      indexOfChargeLine = fieldName.split(formatedFieldName)[1];

      formatedFieldValue = getMappedChargeType(fieldValue) || fieldValue;

      commit("updateModalConfigureProductFormData", {
        fieldName: formatedFieldName,
        fieldValue: formatedFieldValue,
        indexOfChargeLine
      });
    } else {
      commit("updateModalConfigureProductFormData", {
        fieldName,
        fieldValue,
        indexOfChargeLine
      });
    }
    if (isFormValidated) {
      isFormValid = validateForm(
        formValidators,
        formFields,
        "configureProduct",
        ({ prop, isValid, errorText }) => {
          commit("setFormErrorFields", { prop, isValid, errorText });
        }
      );
    }
    if (isFormValid) {
      commit("setModalSubmitButtonIsDisabled", false);
    }
  },
  updateModalConfigureProductServiceModes(
    { state, commit },
    { fieldName, fieldValue }
  ) {
    const {
      isFormValidated,
      formFields,
      formValidators
    } = state.modalController.data;
    let isFormValid = false;
    commit("configureProductAddServiceMode", { fieldName, fieldValue });
    if (isFormValidated) {
      isFormValid = validateForm(
        formValidators,
        formFields,
        "configureProduct",
        ({ prop, isValid, errorText }) => {
          commit("setFormErrorFields", { prop, isValid, errorText });
        }
      );
    }
    if (isFormValid) {
      commit("setModalSubmitButtonIsDisabled", false);
    }
  },
  applyProductChanges({ state, commit, dispatch }) {
    const {
      isNewProduct,
      productGroup,
      formFields,
      formValidators
    } = state.modalController.data;
    let isFormValid = validateForm(
      formValidators,
      formFields,
      "configureProduct",
      ({ prop, isValid, errorText }) => {
        commit("setFormErrorFields", { prop, isValid, errorText });
      }
    );
    commit("setIsFormValidated", true);
    if (!isFormValid) {
      commit("setModalSubmitButtonIsDisabled", true);
      return;
    }
    const product = extractDataFromForm(formFields);
    if (isNewProduct) {
      commit("addNewProduct", { productGroup, product });
    } else {
      commit("editExistingProduct", { productGroup, product });
    }
    dispatch("closeModal");
  },
  duplicateProduct({ commit }, { product, productGroup }) {
    const duplicateString = "[Duplicate] ";
    const originalProductId = product.productId;
    const duplicateProduct = _.cloneDeep(product);
    duplicateProduct.productId = generateUUID();
    duplicateProduct.productName = duplicateString.concat(product.productName);
    duplicateProduct.defaultProduct = false;
    if (duplicateProduct.ports.length > 0) {
      duplicateProduct.ports.forEach(port => {
        port.portId = generateUUID();
        port.productId = duplicateProduct.productId;
      });
    }
    commit("addDuplicateProduct", {
      productGroup,
      duplicateProduct,
      originalProductId
    });
    commit("setEditCountryFormDataChanged", true);
  },
  showFormErrorText({ commit }, errorText) {
    commit("showFormErrorText", errorText);
  },
  hideFormErrorText({ commit }) {
    commit("hideFormErrorText");
  },
  updateModalConfigurePortChargesFormData(
    { state, commit },
    { fieldName, fieldValue }
  ) {
    const {
      formFields,
      formValidators,
      isFormValidated
    } = state.modalController.data;
    let indexOfChargeLine, formatedFieldName, formatedFieldValue, isFormValid;

    if (
      fieldName.includes("unitPrice") ||
      fieldName.includes("chargeUnit") ||
      fieldName.includes("chargeType")
    ) {
      formatedFieldName = fieldName.includes("unitPrice")
        ? "unitPrice"
        : fieldName.includes("chargeUnit")
        ? "chargeUnit"
        : "chargeType";
      indexOfChargeLine = fieldName.split(formatedFieldName)[1];
      formatedFieldValue = getMappedChargeType(fieldValue) || fieldValue;
      commit("updateModalConfigurePortChargesFormData", {
        fieldName: formatedFieldName,
        fieldValue: formatedFieldValue,
        indexOfChargeLine
      });
    } else {
      commit("updateModalConfigurePortChargesFormData", {
        fieldName,
        fieldValue,
        indexOfChargeLine
      });
    }
    if (isFormValidated) {
      isFormValid = validateForm(
        formValidators,
        formFields,
        "configurePort",
        ({ prop, isValid, errorText }) => {
          commit("setFormErrorFields", { prop, isValid, errorText });
        }
      );
    }
    if (isFormValid) {
      commit("setModalSubmitButtonIsDisabled", false);
    }
  },
  updateSupportedCarrierCodes({ commit }, { fieldName, fieldValue }) {
    commit("updateModalConfigurePortSupportedBrands", {
      fieldName,
      fieldValue
    });
  },
  updateCurrentPort({ commit }, port) {
    commit("updateCurrentPort", port);
  },
  applyPortChargesChanges({ state, commit, dispatch }) {
    const {
      isNewPort,
      formFields,
      formValidators,
      productGroup,
      productId
    } = state.modalController.data;
    let isFormValid = validateForm(
      formValidators,
      formFields,
      "configurePort",
      ({ prop, isValid, errorText }) => {
        commit("setFormErrorFields", { prop, isValid, errorText });
      }
    );
    commit("setIsFormValidated", true);

    if (!isFormValid) {
      commit("setModalSubmitButtonIsDisabled", true);
      return;
    }
    const port = extractDataFromForm(formFields);
    const updatedPort = { ...port, productGroup, productId };
    if (isNewPort) {
      if (state.overrideCurrentPortsData) {
        commit("clearExitingPorts", { productGroup, productId });
        commit("overrideCurrentPortsData", false);
      }
      commit("addNewPort", { productGroup, productId, updatedPort });
    } else {
      if (state.overrideCurrentPortsData) {
        commit("overrideExitingPortsWithNewPort", {
          productGroup,
          productId,
          updatedPort
        });
        commit("overrideCurrentPortsData", false);
      } else {
        commit("editExistingPort", { productGroup, updatedPort, productId });
      }
    }
    dispatch("closeModal");
  },
  addNewPriceLineOnCurrentPort({ commit }) {
    commit("addNewPriceLineOnCurrentFormItem", "PRODUCT_CHARGES");
  },
  duplicatePriceLineOnCurrentPort({ commit }, { chargeLine, index }) {
    commit("addDuplicatePriceLineOnCurrentItem", { chargeLine, index });
  },
  deletePriceLineOnCurrentFormItem({ commit }, indexOfPriceLineToDelete) {
    commit("deletePriceLineOnCurrentFormItem", indexOfPriceLineToDelete);
  },
  setProductToDelete({ commit }, { product, productGroup }) {
    commit("setProductToDelete", { product, productGroup });
  },
  unsetProductToDelete({ commit }) {
    commit("unsetProductToDelete");
  },
  deleteProduct({ commit }, { product, productGroup }) {
    commit("deleteExistingProduct", { productGroup, product });
    commit("setProductToDelete", { product, productGroup });
  },
  deletePort({ commit }, port) {
    commit("deleteCurrentPort", port);
    commit("setEditCountryFormDataChanged", true);
  },
  duplicatePort({ commit }, port) {
    const duplicatePort = _.cloneDeep(port);
    duplicatePort.portId = generateUUID();
    commit("duplicateCurrentPort", { port, duplicatePort });
    commit("setEditCountryFormDataChanged", true);
  },
  setOriginalCountryData({ commit }) {
    commit("setOriginalCountryDataValues");
  },
  setLoaderState({ commit }, isLoading) {
    commit("setLoaderState", isLoading);
  },
  enableAddProductButton({ commit }, isEnabled) {
    commit("enableAddProductButton", isEnabled);
  },
  removePortNameValue({ commit }) {
    commit("removePortNameValue");
  },
  overrideCurrentPortsData({ commit }, overridePorts) {
    commit("overrideCurrentPortsData", overridePorts);
  },
  updateCurrentProductPorts({ commit }, { portName, geoId, portId }) {
    commit("updateCurrentProductPorts", { portName, geoId, portId });
  }
};

export default actions;
