<template>
  <div class="notification-box">
    <div class="heading">
      <BaseIcon icon="info-circle" size="20" />
      <div class="title">{{ "noAccess.title" | i18n }}</div>
    </div>
    <div>
      {{ "noAccess.desc" | i18n }}
    </div>
  </div>
</template>
<script>
import { BaseIcon } from "@maersk/vue-core";
export default {
  name: "NoAccess",
  components: {
    BaseIcon
  }
};
</script>
<style lang="scss" scoped>
.notification-box {
  margin-top: 4px;
  padding: 16px;
  border: 1px solid #e9b2b7;
  border-radius: 4px;
  background: #e9b2b7;
  .heading {
    display: flex;
    i {
      position: relative;
      top: 2px;
      left: -3px;
    }
    .title {
      font-weight: 600;
    }
  }
}
</style>
