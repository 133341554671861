export default {
  info (message, ...args) {
    try {
      if (typeof (console) !== 'undefined' && console.info !== undefined && typeof console.info === 'function') {
        console.info(message, args);
      }
    } catch (e) {
      const log = Function.prototype.bind.call(console.info, console);
      log.apply(console, [message, args]);
    }
  },
  debug (message, ...args) {
    try {
      if (typeof (console) !== 'undefined' && console.debug !== undefined && typeof console.debug === 'function') {
        console.debug(message, args);
      }
    } catch (e) {
      const log = Function.prototype.bind.call(console.debug, console);
      log.apply(console, [message, args]);
    }
  },
  warn (message, ...args) {
    try {
      if (typeof (console) !== 'undefined' && console.warn !== undefined && typeof console.warn === 'function') {
        console.warn(message, args);
      }
    } catch (e) {
      const log = Function.prototype.bind.call(console.warn, console);
      log.apply(console, [message, args]);
    }
  },
  error (message, ...args) {
    try {
      if (typeof (console) !== 'undefined' && console.error !== undefined && typeof console.error === 'function') {
        console.error(message, args);
      }
    } catch (e) {
      const log = Function.prototype.bind.call(console.error, console);
      log.apply(console, [message, args]);
    }
  },
  trace (message, ...args) {
    try {
      if (typeof (console) !== 'undefined' && console.trace !== undefined && typeof console.trace === 'function') {
        console.trace(message, args);
      }
    } catch (e) {
      const log = Function.prototype.bind.call(console.trace, console);
      log.apply(console, [message, args]);
    }
  }
};
