<template>
  <div class="ports-table-item">
    <div class="port-name">
      {{ port.portName }}
    </div>

    <div v-if="port.supportedCarrierCodes.includes('MAEU')">
      <BaseIcon
        icon="check"
        size="24"
        class="mearsk-brand-spacing check-color"
      />
    </div>
    <div v-else>
      <BaseIcon
        icon="times"
        size="24"
        class="mearsk-brand-spacing wrong-color"
      />
    </div>
    <div v-if="port.supportedCarrierCodes.includes('SEAU')">
      <BaseIcon
        icon="check"
        size="24"
        class="sealand-brand-spacing check-color"
      />
    </div>
    <div v-else>
      <BaseIcon
        icon="times"
        size="24"
        class="sealand-brand-spacing wrong-color"
      />
    </div>
    <div v-if="port.supportedCarrierCodes.includes('TWIL')">
      <BaseIcon
        icon="check"
        size="24"
        class="safmarine-brand-spacing check-color"
      />
    </div>
    <div v-else>
      <BaseIcon
        icon="times"
        size="24"
        class="safmarine-brand-spacing wrong-color"
      />
    </div>
    <div v-if="port.supportedCarrierCodes.includes('HSUD')">
      <BaseIcon
        icon="check"
        size="24"
        class="safmarine-brand-spacing check-color"
      />
    </div>
    <div v-else>
      <BaseIcon
        icon="times"
        size="24"
        class="safmarine-brand-spacing wrong-color"
      />
    </div>
    <div>
      <p v-for="chargeLine in port.chargeLines" :key="chargeLine.chargeId">
        {{ Number(chargeLine.unitPrice).toFixed(2) }}
      </p>
    </div>
    <div>
      <p v-for="chargeLine in port.chargeLines" :key="chargeLine.chargeId">
        {{ mapChargeType(chargeLine.chargeUnit) }}
      </p>
    </div>
    <div>
      <p v-for="chargeLine in port.chargeLines" :key="chargeLine.chargeId">
        {{ mapChargeTypeOn(chargeLine.chargeType) }}
      </p>
    </div>
    <div class="modify-btn-container">
      <a @click.prevent="handleEditPort()" href="javascript:void(0)" class="icon-padding"
        ><BaseIcon icon="pencil" size="24" />
      </a>
      <a
        @click.prevent="handleDuplicatePort(port)"
        href="javascript:void(0)"
        class="icon-padding"
        ><BaseIcon icon="file-copy" size="24" />
      </a>
      <a @click.prevent="handleDeletePort(port)" href="javascript:void(0)"
        ><BaseIcon icon="trash" size="24" />
      </a>
    </div>
  </div>
</template>

<script>
import i18nFilter from "../../../filters/i18nFilter";

import {
  createPortChargeFormObject,
  getMappedChargeType,
  getMappedChargesFor
} from "../../../formUtilities";

import { mapActions, mapState } from "vuex";
import { BaseIcon } from "@maersk/vue-core";
import _ from "lodash";

export default {
  name: "ChbPortChargesFormTableItem",
  components: {
    BaseIcon
  },
  props: {
    portGroup: { type: String, required: true },
    port: { type: Object, required: true },
    productGroup: { type: String, required: true },
    productId: { type: String, required: true },
    currentProductPorts: { type: Array, required: true }
  },
  computed: {
    ...mapState({
      currencyCode: state =>
        state.editCountryController.formData.currencyCode.value,
      currentProductState: state =>
        state.editCountryController.formData.productGroups,
      originalCountryProductsData: state => state.originalCountryProductsData
    })
  },
  methods: {
    ...mapActions([
      "openModal",
      "deletePort",
      "duplicatePort",
      "setEditCountryFormDataChanged"
    ]),
    verifyFormDataHasChanged() {
      const isSameObject = _.isEqual(
        this.originalCountryProductsData,
        this.currentProductState
      );
      if (!isSameObject) {
        this.setEditCountryFormDataChanged(true);
      } else {
        this.setEditCountryFormDataChanged(false);
      }
    },
    handleDeletePort(port) {
      this.deletePort(port);
      this.verifyFormDataHasChanged();
    },
    handleDuplicatePort(port) {
      this.duplicatePort(port);
    },
    handleEditPort: function() {
      const modalOptions = {
        contentComponent: "ChbConfigureEditPortChargesForm",
        data: {
          currentProductPorts: _.cloneDeep(this.currentProductPorts),
          portGroup: this.portGroup,
          productId: this.productId,
          productGroup: this.productGroup,
          isNewPortCharge: true,
          isFormValidated: false,
          isFormValid: true,
          formFields: createPortChargeFormObject(this.port),
          formValidators: {
            portName: ["required"]
          },
          formError: {
            isVisible: false,
            errorText: ""
          }
        },
        headerText: i18nFilter(
          "chbAdmin.editPage.modal.editPortCharges.header.label"
        ),
        modalWidth: "810px",
        submitButtonOptions: {
          isDisabled: false,
          label: i18nFilter(
            "chbAdmin.editPage.modal.editPortCharges.button.submit.label"
          )
        },
        deleteButtonOptions: {
          isVisible: true,
          isDisabled: false,
          label: i18nFilter(
            "chbAdmin.editPage.modal.configurePortCharges.button.delete.label"
          )
        }
      };

      this.openModal(modalOptions);
    },
    mapChargeTypeOn(chargeType) {
      return getMappedChargesFor(chargeType);
    },
    mapChargeType(chargeType) {
      return getMappedChargeType(chargeType);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/settings";
.ports-table-item {
  display: grid;
  width: 100%;
  grid-template-columns: 14% 8% 8% 8% 17% 12% 7% 10% 14%;
  margin-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dbdbdb;
  .check-color {
    color: #40ab35;
  }
  .wrong-color {
    color: #b80012;
  }
  .mearsk-brand-spacing {
    margin-left: 16px;
  }
  .sealand-brand-spacing {
    margin-left: 24px;
  }
  .safmarine-brand-spacing {
    margin-left: 30px;
  }

  .port-price {
    @include grid-col(3);

    &-not-available {
      @include grid-col(6);
    }
  }

  .port-per {
    @include grid-col(3);
  }

  .port-edit {
    @include grid-col(1);
  }

  p {
    margin: 0;
  }

  a {
    color: #1e638e;
    font-weight: bold;
  }
  .modify-btn-container {
    display: flex;
    justify-content: flex-end;
    .icon-padding {
      padding-right: 24px;
    }
  }
  i {
    color: #00243d;
  }
}
</style>
