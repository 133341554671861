<template>
  <ChbSection>
    <div class="chb-edit-page-footer theme--light">
      <a
        href="javascript:void(0)"
        role="button"
        class="button button--tertiary delete-country"
        @click.prevent="handleDelete"
        :class="{ 'button--disabled': isNewCountry || isEditEnabled }"
        >{{ "chbAdmin.editPage.form.editCountry.button.delete.label" | i18n }}
      </a>
      <div>
        <a
          href="javascript:void(0)"
          role="button"
          class="button button--tertiary button--cancel"
          @click="handleCancel"
        >
          {{
            "chbAdmin.editPage.form.editCountry.button.cancel.label" | i18n
          }}</a
        >
        <a
          href="javascript:void(0)"
          role="button"
          class="button button--primary button--submit"
          @click="handleSubmit"
          :class="{ 'button--disabled': isEditEnabled || !isFormDataChanged }"
        >
          {{ "chbAdmin.editPage.form.editCountry.button.submit.label" | i18n }}
        </a>
      </div>
    </div>
  </ChbSection>
</template>

<script>
import {mapState, mapActions, mapMutations} from "vuex";
import ChbSection from "../../common/ChbSection";
import i18nFilter from "../../../filters/i18nFilter";

export default {
  name: "ChbEditPageFooter",
  components: { ChbSection },
  computed: {
    ...mapState({
      isNewCountry: state => state.editCountryController.isNewCountry,
      isFormValid: state => state.editCountryController.isFormValid,
      productGroups: state =>
        state.editCountryController.formData.productGroups.value,
      isFormDataChanged: state => state.editCountryController.isFormDataChanged,
      isEditEnabled: state => state.editCountryController.isEditEnabled,
      isLoading: state => state.isLoading
    })
  },
  methods: {
    ...mapMutations([
      "updateCountryInCountries"
    ]),
    ...mapActions([
      "goToCountriesOverviewPage",
      "openModal",
      "addCurrentCountry",
      "editCurrentCountry",
      "validateEditPageSubmit",
      "openLeavingWithoutSavingModal",
      "setLoaderState"
    ]),
    async handleSubmit() {
      await this.validateEditPageSubmit();
      if (this.isNewCountry) {
        this.setLoaderState(true);
        await this.addCurrentCountry();
      } else {
        this.setLoaderState(true);
        await this.editCurrentCountry();
      }
    },
    handleCancel(event) {
      if (this.isFormDataChanged) {
        event.preventDefault();
        this.openLeavingWithoutSavingModal();
      } else {
        this.goToCountriesOverviewPage();
      }
    },
    handleDelete() {
      let modalOptions;
      if (this.areThereProducts()) {
        modalOptions = {
          contentComponent: "ChbDeleteCountryValidationError",
          submitButtonOptions: {
            label: i18nFilter(
              "chbAdmin.editPage.modal.deleteCountryValidationError.button.submit.label"
            )
          },
          cancelButtonOptions: {
            isVisible: false,
            isDisabled: false
          }
        };
      } else {
        modalOptions = {
          headerText: i18nFilter(
            "chbAdmin.editPage.modal.confirmationDialog.deleteCountry.label"
          ),
          contentComponent: "ChbDeleteCountryConfirmationDialog",
          submitButtonOptions: {
            label: i18nFilter(
              "chbAdmin.editPage.modal.confirmationDialog.button.submit.label"
            )
          },
          cancelButtonOptions: {
            label: i18nFilter(
              "chbAdmin.editPage.modal.confirmationDialog.button.cancel.label"
            ),
            isVisible: true,
            isDisabled: false
          }
        };
      }

      this.openModal(modalOptions);
    },
    areThereProducts() {
      for (let i = 0; i < this.productGroups.length; i++) {
        if (this.productGroups[i].products.length) {
          return true;
        }
      }

      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/settings";

.chb-edit-page-footer {
  display: flex;
  justify-content: space-between;
  margin: $chb-spacing-large 0;
  button {
    margin-left: $chb-spacing-large;
  }
}
</style>
