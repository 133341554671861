export default {
  bind (el, binding, vnode) {
    el['@clickoutside'] = e => {
      if (!el.contains(e.target) && binding.expression && vnode.context[binding.expression]) {
        binding.value();
      }
    };
    document.addEventListener('click', el['@clickoutside'], true);
  },
  unbind (el) {
    document.removeEventListener('click', el['@clickoutside'], true);
  }
};
