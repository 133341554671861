import { decodeJwtBody } from "@maersk/shared-js";
import applicationContext from "@/ApplicationContext";
import { consumerKeys } from "@/utilities/CONSTANTS";

export function setUserInfoToLocalStorage() {
  const decode = decodeJwtBody(window.localStorage.getItem("[iam]id_token"));
  if (decode) {
    window.localStorage.setItem(
      "loggedInUserInfo",
      JSON.stringify({
        firstname: decode.firstname || "Admin",
        lastname: decode.lastname || "",
        isCHBAdmin: decode.roles.includes("CHBAdmin")
      })
    );
  } else {
    window.localStorage.removeItem("loggedInUserInfo");
    window.localStorage.removeItem("[iam]id_token");
  }
}

export function getAuthHeaders() {
  const idToken = window.localStorage.getItem("[iam]id_token");
  if (idToken) {
    return {
      Authorization: `Bearer ${idToken}`,
      "Consumer-Key": consumerKeys[applicationContext.env]
    };
  } else {
    return {};
  }
}

export function setIDToken() {
  const hashedStr = window.location.hash.split("&")[1];
  const idToken = hashedStr ? hashedStr.replace("id_token=", "") : '';
  if (idToken) {
    window.localStorage.setItem("[iam]id_token", idToken);
  }
  return idToken;
}

export function isUserLoggedIn() {
  const decodedStr = decodeJwtBody(window.localStorage.getItem("[iam]id_token"));
  return decodedStr && Date.now() < decodedStr.exp * 1000;
}
