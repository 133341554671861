<template>
  <div id="chb-error">
    <div class="chb-error-content">
      <div class="errorContainer">
        <div class="errorName">404</div>
        <div class="errorCode">{{ 'chbAdmin.error.404.label' | i18n }}</div>
        <p class="errorDescription">{{ 'chbAdmin.error.404.message' | i18n }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Error404Page'
};
</script>
<style lang="scss" scoped>
  @import "../scss/settings";

  .chb-error-content {
    @include grid-row();
    padding-bottom: $chb-spacing;
  }

  $AO-error-content-copy-text: 16px;
  $AO-error-content-copy-heading: 40px;
  $AO-error-content-copy-heading-title: 26px;
  $AO-error-content-spacing-unit: 20px;

  .errorBold {
    font-weight: bold;
  }

  .errorHeader {
    display: none !important;
  }

  .errorContainer {
    margin-top: 8 * $AO-error-content-spacing-unit;
    margin-bottom: 4 * $AO-error-content-spacing-unit;
  }

  .errorName {
    font-family: $body-font-family;
    font-size: $AO-error-content-copy-heading-title;
  }
  .errorCode {
    font-family: $body-font-family;
    font-size: $AO-error-content-copy-heading;
  }
  .errorDescription {
    font-family: $body-font-family;
    font-size: $AO-error-content-copy-text;
    width: 40 * $AO-error-content-spacing-unit;
    margin-top: 2 * $AO-error-content-spacing-unit;
  }
</style>
