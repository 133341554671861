import axios from "axios";
import applicationContext from "@/ApplicationContext";
import {getAuthHeaders} from "@/authentication";
import store from "@/store";


export const fetchOnboardingData = async (countryCode) => {
  try {
    await store.dispatch("setLoaderState", true);
    const response = await axios({
      method: "GET",
      url: `${
        applicationContext.contentLocation
      }/customs-services/customs-rules/customs-onboard/${countryCode}`,
      headers: getAuthHeaders()
    });
    const { result } = response.data;
    return result;
  } catch (errorResponse) {
    throw errorResponse;
  } finally {
    await store.dispatch("setLoaderState", false);
  }
}
