export const appUtilities = {
  sortByAsc: (needToSort = [], sortOn = "") => {
    return needToSort.sort((prev, next) =>
      prev.hasOwnProperty(sortOn) && next.hasOwnProperty(sortOn)
        ? prev[`${sortOn}`].toUpperCase() < next[`${sortOn}`].toUpperCase()
          ? -1
          : 1
        : 0
    );
  },
  sortByDesc: (needToSort = [], sortOn = "") => {
    return needToSort.sort((prev, next) =>
      prev.hasOwnProperty(sortOn) && next.hasOwnProperty(sortOn)
        ? prev[`${sortOn}`].toUpperCase() < next[`${sortOn}`].toUpperCase()
          ? 1
          : -1
        : 0
    );
  }
};

export function indexofPortForOperation(productGroups, port){
  const products = productGroups.find(
    el => el.productGroupId === port.productGroup
  ).products;
  const portsList = products.find(el => el.productId === port.productId).ports;
  const indexOfCurrentPort = portsList.findIndex(item => item.portId === port.portId);
  return {portsList, indexOfCurrentPort};
}
