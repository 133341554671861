import _ from 'lodash';
import { cookie } from '@/cookie';
import log from '../logger';
import axios from 'axios';
import applicationContext from '../ApplicationContext';
import defaultI18nMap from "../resources/defaultI18nMap.json";

let i18nMap = {};

/**
 * Loads language selected by customer, by performing lookup for currently set language cookie value.*
 */
export async function loadSelectedLang () {
  const selectedLanguage = cookie.getCookie('selectedLang') || 'en';
  try {
    const response = await axios.get(`${applicationContext.assetsLocation}/i18n/VasChbAdmin/${selectedLanguage}.json`);
    log.info('Loaded language data', selectedLanguage, response.data);
    i18nMap = response.data;
  } catch (error) {
    log.error('Failed to load language', selectedLanguage, error);
    i18nMap = {};
  }
}

export default function (value, templateArgs) {
  const i18nValue = i18nMap[value] || defaultI18nMap[value];

  if (!i18nValue) {
    return value;
  }

  if (templateArgs === undefined) {
    return i18nValue;
  }

  return _.template(i18nValue)(templateArgs);
}
