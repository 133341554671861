<template>
  <div>
    <div class="product-container">
      <div class="product-container__header">
        <div class="product-container__header__subheading">
          <BaseIcon icon="arrows-down-up" size="24" />
          <span
            class="font--display-1 product-container__header__subheading__product-name"
          >
            {{ product.productName }}
          </span>
        </div>
        <div class="product-container__header__icons">
          <div
            class="default-product-section product-container__header__icons__spacing"
          >
            <input
              type="radio"
              :id="product.productId"
              :name="
                productGroup.includes('exportCustomsClearance')
                  ? 'export-default-product'
                  : 'import-default-product'
              "
              :value="product.defaultProduct"
              @input="setDefaultProduct($event)"
              :checked="product.defaultProduct"
            />
            <label
              :for="
                productGroup.includes('exportCustomsClearance')
                  ? 'export-default-product'
                  : 'import-default-product'
              "
              >{{ "chbAdmin.editPage.product.defaultProduct" | i18n }}</label
            >
          </div>
          <tooltip
            :tooltipClasses="cssClass"
            :tooltipDisabled="tooltipDisabled"
          >
            <a
              href="javascript:void(0)"
              @click.prevent="handleEditProduct(product)"
            >
              <BaseIcon
                class="product-container__header__icons__spacing"
                icon="pencil"
                size="24"
              />
            </a>
            <template v-slot:tooltipText>{{
              "chbAdmin.editPage.product.editToolTip" | i18n
            }}</template></tooltip
          >
          <tooltip
            :tooltipClasses="cssClass"
            :tooltipDisabled="tooltipDisabled"
          >
            <a
              href="#"
              @click.prevent="duplicateCurrentProduct(product, productGroup)"
            >
              <BaseIcon
                class="product-container__header__icons__spacing"
                icon="file-copy"
                size="24"
              />
            </a>
            <template v-slot:tooltipText>{{
              "chbAdmin.editPage.product.duplicateToolTip" | i18n
            }}</template></tooltip
          >
          <tooltip
            :tooltipClasses="cssClass"
            :tooltipDisabled="tooltipDisabled"
          >
            <a
              href="javascript:void(0)"
              @click.prevent="deleteProduct(product, productGroup)"
            >
              <BaseIcon
                class="product-container__header__icons__spacing product-container__header__icons__deleteIcon"
                icon="trash"
                size="24"
              />
            </a>
            <template v-slot:tooltipText>{{
              "chbAdmin.editPage.product.deleteToolTip" | i18n
            }}</template></tooltip
          >
        </div>
      </div>
      <div class="product-container__contents">
        <div class="product-container__contents__overview">
          <ChbContentDetail :title="'chbAdmin.editPage.form.showPorts.applies.label' | i18n">{{ checkPorts() }}</ChbContentDetail>
      <div class="product-container__contents__overview--brands">
        <div v-for="brand in brands" :key="brand.name">
          <ChbContentDetail :title="`chbAdmin.editPage.form.editCountry.productFormTable.supportedBrands.${
                brand.name
              }.label` | i18n" description="" >
              <span
                v-if="supportBrandOnPorts(brand.name)"
                :class="brand.className"
              >
              <BaseIcon icon="check" size="24" class="check-color" />
            </span>
            <span v-else :class="brand.className">
              <BaseIcon icon="times" size="24" class="wrong-color" />
            </span>
          </ChbContentDetail>
        </div>
      </div>
          <ChbContentDetail :title="'chbAdmin.editPage.form.editCountry.productFormTable.availableOn'
          | i18n" >
            <div class="services-on-container">
              <div
                class="shipment-mode-container"
                v-for="shipmentMode in shipmentModes"
                :key="shipmentMode.id"
              >
              <span v-if="availableShipments(shipmentMode.id)">
                <BaseIcon icon="check" size="24" class="check-color" />
              </span>
                <span v-else>
                <BaseIcon icon="times" size="24" class="wrong-color" />
              </span>
                <span class="shipment-text">{{
                    `chbAdmin.editPage.form.showPorts.${shipmentMode.name}` | i18n
                  }}</span>
              </div>
            </div>
          </ChbContentDetail>
          <ChbContentDetail :title="'chbAdmin.editPage.form.editCountry.productFormTable.gcssChargeCode' | i18n">
            {{ product.chargeCode || "None"}}
          </ChbContentDetail>
      </div>
        <div class="product-container__contents__showDetails">
          <BaseIcon
            :icon="showDetails ? 'chevron-up' : 'chevron-down'"
            class="showDetails__icon"
          />
          <span
            @click="toggelShowDeatils"
            class="product-container__contents__showDetails__text"
          >
            {{
              showDetails
                ? "chbAdmin.editPage.form.showPorts.hideProductDetails"
                : "chbAdmin.editPage.form.showPorts.showProductDetails" | i18n
            }}</span
          >
        </div>
        <div v-if="showDetails" class="detailsContainer">
          <div class="detailsContainer__description">
            <span class="detailsContainer__description__label">{{
                "chbAdmin.modal.configureProduct.productDescription.label" | i18n
              }}</span>
            <div class="detailsContainer__description__value">
              {{ product.productDescription }}
            </div>
          </div>
          <div>
            <ChbPortChargesFormTable
              :ports="product.ports"
              :portGroup="productGroup"
              :productId="product.productId"
              :productGroup="productGroup"
            />
          </div>
        </div>
    </div>
  </div>
  </div>
</template>
<script>
import { BaseIcon } from "@maersk/vue-core";
import { mapActions, mapMutations } from "vuex";
import { createProductFormObject } from "@/formUtilities";
import i18nFilter from "@/filters/i18nFilter";
import { Tooltip } from "@maersk/vue-core";
import ChbPortChargesFormTable from "./ChbPortChargesFormTable";
import ChbContentDetail from "@/components/editCountry/products/ChbContentDetail.vue";

export default {
  name: "ProductDetails",
  components: {
    BaseIcon,
    Tooltip,
    ChbPortChargesFormTable,
    ChbContentDetail
  },
  data() {
    return {
      showDetails: false,
      brands: [
        {
          name: "MAEU",
          className: "mearsk-brand-spacing"
        },
        {
          name: "SEAU",
          className: "mearsk-brand-spacing"
        },
        {
          name: "TWIL",
          className: "mearsk-brand-spacing"
        },
        {
          name: "HSUD",
          className: "mearsk-brand-spacing"
        }
      ],
      shipmentModes: [
        {
          id: "CY",
          name: "CYShipments"
        },
        {
          id: "SD",
          name: "SDShipments"
        }
      ]
    };
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    productGroup: {
      type: String,
      required: true
    },
    tooltipPosition: {
      default: ("Tooltip position",
      {
        Above: "tooltip--above",
        Below: "tooltip--below",
        Right: "tooltip--right-top",
        Left: "tooltip--left-top"
      },
      "tooltip--above")
    },
    arrowPosition: {
      default: ("Arrow position",
      {
        Left: "tooltip--left",
        Center: "tooltip--center",
        Right: "tooltip--right"
      },
      "tooltip--left")
    },
    tooltipOneLine: {
      default: ("Show tooltip text on one line", true)
    },
    tooltipText: {
      default: ("Tooltip text", "Edit")
    },
    tooltipDisabled: {
      default: ("Tooltip disabled", false)
    },
    defaultProduct: {
      type: Object,
      required: false,
      default: null
    },
    exportProducts: {
      type: Array,
      required: false
    },
    importProducts: {
      type: Array,
      required: false
    }
  },
  computed: {
    cssClass: function() {
      return `${this.tooltipPosition} ${this.arrowPosition} ${
        this.tooltipOneLine ? "tooltip--one-line" : ""
      }`;
    }
  },
  methods: {
    ...mapActions([
      "openModal",
      "duplicateProduct",
      "setProductToDelete",
      "setEditCountryFormDataChanged"
    ]),
    ...mapMutations(["editExistingProduct"]),
    checkPorts() {
      let ports = [];
      if (this.product.ports.length > 0) {
        ports = this.product.ports.map(port => port.portName);
        return ports.some(port => port === "All Ports")
          ? "All Ports"
          : ports.length === 1
          ? `${ports.length} Port`
          : `${ports.length} Ports`;
      } else return "No Ports";
    },
    supportBrandOnPorts(brandName) {
      if (this.product.ports.length) {
        const data = this.product.ports.map(item => item.supportedCarrierCodes);
        const supportedBrandsList = [].concat.apply([], data);
        return supportedBrandsList.length > 0
          ? supportedBrandsList.some(brand => brand === brandName)
          : false;
      } else return false;
    },
    availableShipments(offeredServiceMode) {
      const data = this.product.supportedShipmentServiceModes.some(
        serviceMode => serviceMode === offeredServiceMode
      );
      return data;
    },
    toggelShowDeatils() {
      return (this.showDetails = !this.showDetails);
    },
    deleteProduct(product, productGroup) {
      this.setProductToDelete({ product, productGroup });
      let modalOptions;
      modalOptions = {
        contentComponent: "ChbDeleteProductConfirmationDialogue",
        headerText: product.defaultProduct
          ? i18nFilter("label.alert")
          : i18nFilter("chbAdmin.editPage.modal.deleteProduct"),
        submitButtonOptions: product.defaultProduct
          ? {
              label: i18nFilter(
                "chbAdmin.editPage.modal.deleteCountryValidationError.button.submit.label"
              )
            }
          : {
              label: i18nFilter("chbAdmin.editPage.modal.deleteProduct")
            },
        cancelButtonOptions: product.defaultProduct
          ? {}
          : {
              label: i18nFilter("chbAdmin.modal.button.cancel.label.default"),
              isVisible: true,
              isDisabled: false
            }
      };
      this.openModal(modalOptions);
    },
    handleEditProduct: function(product) {
      const modalOptions = {
        contentComponent: "ChbConfigureProductForm",
        data: {
          productGroup: this.productGroup,
          isNewProduct: false,
          isFormValidated: false,
          isFormValid: true,
          formFields: createProductFormObject(product),
          formValidators: {
            productName: ["required"],
            productDescription: ["required"],
            supportedShipmentServiceModes: ["notEmpty"]
          },
          formError: {
            isVisible: false,
            errorText: ""
          }
        },
        headerText:
          this.productGroup === "importCustomsClearance"
            ? i18nFilter("chbAdmin.modal.editProduct.import.header.label")
            : i18nFilter("chbAdmin.modal.editProduct.export.header.label"),
        submitButtonOptions: {
          isDisabled: false,
          label: i18nFilter("chbAdmin.modal.editProduct.submit.button.label")
        },
        deleteButtonOptions: {
          isVisible: true,
          isDisabled: false,
          label: i18nFilter("chbAdmin.modal.editProduct.delete.button.label")
        }
      };
      this.openModal(modalOptions);
    },
    duplicateCurrentProduct(product, productGroup) {
      this.duplicateProduct({ product, productGroup });
    },
    setDefaultProduct(event) {
      this.unsetDefaultProduct();
      this.editExistingProduct({
        productGroup: this.productGroup,
        product: { ...this.product, defaultProduct: event.target.checked }
      });
      this.$emit("verifyFormDataChanged");
    },
    unsetDefaultProduct() {
      const products = this.productGroup.includes("exportCustomsClearance")
        ? this.exportProducts
        : this.importProducts;
      products.forEach(product => {
        if (product.defaultProduct) {
          this.editExistingProduct({
            productGroup: this.productGroup,
            product: { ...product, defaultProduct: false }
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.product-container {
  margin-top: 24px;
  padding: 16px 0 16px 16px;
  background: #f7f7f7;
  &:hover {
    cursor: all-scroll;
    content: "abc";
  }
  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &__subheading {
      display: flex;
      &__product-name {
        padding-left: 24px;
      }
    }
    a {
      color: #00243d;
    }
    &__icons {
      display: flex;
      &__spacing {
        padding-right: 24px;
      }
    }
    .default-product-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      input[type="radio"] {
        appearance: none;
        width: 20px;
        height: 20px;
        border: 1px solid #cfcfcf;
        background: #ffffff;
        border-radius: 50%;
        padding: 3px;
        cursor: pointer;
      }
      input[type="radio"]:checked {
        background-color: #42b0d5;
        border: 1px solid #42b0d5;
        background-clip: content-box;
      }
      label {
        margin-left: 12px;
      }
    }
  }
  &__contents {
    margin-top: 32px;
    padding-bottom: 16px;
    &__overview {
      width: 85%;
      display: grid;
      gap: 0 28px;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: repeat(auto-fill, minmax(200px, 3fr));
      @media (min-width: 990px){
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 3fr 2fr 1fr;  
      }
      
      &--brands {
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(4, 1fr);
        justify-items: center;
      }
    }
    
    &__showDetails {
      margin-top: 40px;
      color: #0073ab;
      display: flex;
      align-items: center;
      cursor: pointer;
      &__icon {
        margin-top: 4px;
      }
      &__text {
        color: #0073ab;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        margin-left: 8px;
      }
    }
    .detailsContainer {
      &__description {
        display: flex;
        flex-direction: column;
        margin-top: 36px;

        &__label {
          font-size: 14px;
          line-height: 20px;
          color: #878787;
        }
        &__value {
          font-size: 16px;
          line-height: 24px;
          color: #141414;
        }
      }
    }
    @import "../../../scss/settings";

    .products-table-header-labels {
      width: 85%;
      display: grid;
      grid-template-columns: 18% 10% 10% 18% 10% 10%;
      font-size: 14px;
      line-height: 20px;
      color: #878787;
    }

    .products-action-buttons {
      @include grid-col(12);
      h5 {
        display: inline-block;
        cursor: pointer;
        margin-top: $chb-spacing-small;
        color: $chb-marine-blue;
        text-transform: uppercase;
      }
    }
  }
}
</style>
