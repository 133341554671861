<template>
  <transition v-if="contentIsReady" name="chb-modal">
    <div class="chb-modal-mask">
      <div class="chb-modal-wrapper" @keydown.esc="closeModal">
        <ChbFocusLock>
          <div class="chb-modal-container" v-bind:style="{ 'width' : modalWidth }">
            <div v-if="headerLabel" class="chb-modal-header">
              <button class="chb-modal-close" tabindex="1" @click="closeModal">
                <span class="icon icon-close"/>
              </button>
              <h4>{{ headerLabel }}</h4>
            </div>
            <div class="chb-modal-body" :class="{'add-country-modal' : contentComponent === 'ChbAddCountryForm'}">
              <component ref="contentComponent" v-bind:is="mainContent" :modalData="modalData"></component>
            </div>
            <div class="chb-modal-footer">
              <footer class="theme--light footer">
                 <a
                  v-if="cancelButtonOptions.isVisible"
                  href="javascript:void(0)"
                  role="button"
                  class="button button--tertiary button--cancel"
                  @click.prevent="handleCancel"
                  :disabled="cancelButtonOptions.isDisabled"
                >{{ cancelButtonOptions.label }}</a>
                <a
                  class="button button--primary button--submit"
                  @click.prevent="handleSubmit"
                  href="javascript:void(0)"
                  role="button"
                  :disabled="submitButtonOptions.isDisabled"
                >{{ submitButtonOptions.label }}</a>
              </footer>
            </div>
          </div>
        </ChbFocusLock>
      </div>
    </div>
  </transition>
</template>

<script>
import ChbFocusLock from '../ChbFocusLock';

import { mapState, mapActions } from 'vuex';

export default {
  name: 'ChbModal',
  components: {
    ChbFocusLock
  },
  data () {
    return {
      mainContent: null,
      contentIsReady: false
    };
  },
  computed: {
    ...mapState({
      headerLabel: state => state.modalController.headerText,
      modalWidth: state => state.modalController.modalWidth,
      contentComponent: state => state.modalController.contentComponent,
      submitButtonOptions: state => state.modalController.submitButtonOptions,
      cancelButtonOptions: state => state.modalController.cancelButtonOptions,
      deleteButtonOptions: state => state.modalController.deleteButtonOptions,
      modalData: state => state.modalController.data
    })
  },
  methods: {
    ...mapActions(['closeModal']),
    importMainContentComponent () {
      import(`./${this.contentComponent}`).then(module => {
        this.mainContent = module.default;
        this.contentIsReady = true;
      });
    },
    handleSubmit () {
      this.$refs.contentComponent.handleSubmit();
    },
    handleCancel () {
      if (this.$refs.contentComponent.handleCancel) {
        this.$refs.contentComponent.handleCancel();
      } else {
        this.closeModal();
      }
    }
  },
  created () {
    this.importMainContentComponent();
    document.body.classList.add('prevent-scroll');
    this.$once('hook:destroyed', () => {
      document.body.classList.remove('prevent-scroll');
    });
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/settings";

.chb-modal-close {
  position: absolute;
  right: 18px;
  top: $chb-spacing;
  cursor: pointer;
}

.chb-modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  padding-top: 5%;
  padding-bottom: 1%;
  overflow-y: scroll;
}

.chb-modal-wrapper {
  display: block;
}

.chb-modal-container {
  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
}

.chb-modal-header h4 {
  font-weight: bold;
  padding: $chb-spacing $chb-spacing-large;
  margin-top: 0;
}

.chb-modal-body {
  font-family: $body-font-family;
  font-size: 14px;
  max-height: 70vh;
  overflow-y:scroll;
}
.add-country-modal{
  overflow-y: unset;
}

.chb-modal-footer {
  text-align: right;
  background: #F7F7F7;
  padding: $chb-spacing $chb-spacing-large;
  .footer{
    display: flex;
    justify-content: flex-end;
  }
}

.chb-modal-enter {
  opacity: 0;
}

.chb-modal-leave-active {
  opacity: 0;
}

.chb-modal-enter .chb-modal-container,
.chb-modal-leave-active .chb-modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.chb-modal-large {
  width: 60%;
  height: 800px;
}

.chb-button-delete {
  float: right;
  margin-left: 0 !important;
  background-color: white;
  border: none;
  text-decoration: underline;
  color: $chb-marine-blue;
  vertical-align: middle;
  padding-left: 0;
}

.icon-trash {
  vertical-align: middle;
  font-size: 23px;
}
</style>
