var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"clickoutside",rawName:"v-clickoutside",value:(_vm.closeSelect),expression:"closeSelect"}]},[(_vm.label && _vm.label.length)?_c('label',{staticClass:"chb-input-label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"chb-select",class:{ 'chb-error': !_vm.isValid }},[_c('div',{staticClass:"chb-select"},[_c('div',{staticClass:"chb-input"},[(_vm.showIcon)?_c('span',{staticClass:"chb-input-icon",class:_vm.selectIcon,on:{"mousedown":function($event){$event.preventDefault();return _vm.toggleSelect.apply(null, arguments)}}}):_vm._e(),_c('input',{ref:"input",class:_vm.showIconClass,attrs:{"name":_vm.name,"disabled":_vm.disabled,"autocomplete":"off","placeholder":!_vm.disabled ? _vm.placeholder : '',"aria-label":_vm.label,"readonly":!_vm.editable,"type":"text"},domProps:{"value":_vm.selectedValueText},on:{"focus":_vm.showSelect,"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeSelect.apply(null, arguments)},_vm.onUserInput],"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();return _vm.typeAheadUp.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();return _vm.typeAheadDown.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.typeAheadSelect.apply(null, arguments)}],"blur":_vm.closeSelect,"mousedown":_vm.onInputClick}}),(!_vm.disabled)?_c('span',{staticClass:"chb-input-icon",class:{
            'icon-arrow-dropdown': !_vm.selectExpanded,
            'icon-arrow-dropup': _vm.selectExpanded
          },on:{"mousedown":function($event){$event.preventDefault();return _vm.toggleSelect.apply(null, arguments)}}}):_vm._e()]),(!_vm.disabled)?_c('div',{staticClass:"chb-custom-dropdown"},[_vm._t("custom-dropdown",function(){return [_c('ul',{staticClass:"chb-select-options",class:{ 'chb-select-active': _vm.selectExpanded },style:({ height: _vm.getListHeight() }),attrs:{"tabindex":"-1"},on:{"mousemove":_vm.setScrollBarActive,"mouseleave":function($event){_vm.scrollbarActive = false}}},[_vm._l((_vm.additionalOptions),function(option,index){return _c('li',{key:index,staticClass:"chb-select-option chb-select-option-add",on:{"mouseover":function($event){_vm.typeAheadPointer = index},"mousedown":function($event){$event.preventDefault();_vm.onAdditionalItemClick(index);
                _vm.closeSelect();}}},[_c('span',{staticClass:"chb-button-link",class:{
                  'chb-button-link-highlight': index === _vm.typeAheadPointer
                }},[_vm._v(_vm._s(_vm._f("i18n")(option.labelKey)))])])}),_vm._l((_vm.selectData),function(item,index){return _c('li',{key:item.id,staticClass:"chb-select-option",class:{
                'chb-select-option-highlight':
                  _vm.additionalOptions.length + index === _vm.typeAheadPointer
              },on:{"mouseover":function($event){_vm.typeAheadPointer = index},"mousedown":function($event){$event.preventDefault();return _vm.selectItem(item)}}},[_c('span',[_vm._v(_vm._s(_vm.itemFormat(item)))])])}),(_vm.selectData.length === 0)?_c('li',{staticClass:"chb-select-option"},[_c('span',[_vm._v(_vm._s(_vm._f("i18n")("chbAdmin.form.select.noMatch.message")))])]):_vm._e()],2)]})],2):_vm._e(),(!_vm.isValid)?_c('p',{staticClass:"chb-error-message"},[_vm._v(_vm._s(_vm.errorText))]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }