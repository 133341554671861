<template>
  <div id="chb-header">
    <ChbSection>
      <div class="admin-header" data-test="admin-header">
        <img src="../../assets/img/maersk-logo.svg" />
        <div class="actions">
          <span class="actions userinfo">
            <i class="icon mi-24px mi-user-circle" aria-hidden="true"></i>
            <span
              >{{ loggedInUser.firstname }} {{ loggedInUser.lastname }}</span
            >
          </span>
          <span>|</span>
          <div class="logout-link" data-test="logout-link" @click="logout()">
            {{ "label.logout" | i18n }}
          </div>
        </div>
      </div>
      <hr />
      <div class="heading ">
        <h3 class="font--display-3">
          {{ "chbAdmin.header.title" | i18n }}
        </h3>
      </div>
      <div class="theme--light">
        <nav class="tabs">
          <div class="tabs__wrapper">
            <ul class="tabs__list">
              <li class="tab tabs__list__item">
                <a href="javascript:void(0)" class="tabs__list__link active">
                  <i
                    class="icon tabs__list__icon mi-24px mi-globe"
                    aria-hidden="true"
                  ></i>
                  {{ "chbAdmin.header.countryConfiguration" | i18n }}
                </a>
              </li>
              <li class="tab tabs__list__item">
                <a href="/admin/contracts" class="tabs__list__link">
                  <i
                    class="icon tabs__list__icon mi-24px mi-file"
                    aria-hidden="true"
                  ></i>
                  {{ "chbAdmin.header.contractConfiguration" | i18n }}
                </a>
              </li>
              <li class="tab tabs__list__item" v-if="loggedInUser.isCHBAdmin">
                <a
                  href="/admin/contracts/access-manager"
                  class="tabs__list__link"
                >
                  <i
                    class="icon tabs__list__icon mi-24px mi-person"
                    aria-hidden="true"
                  ></i>
                  {{ "chbAdmin.header.accessManager" | i18n }}
                </a>
              </li>
              <li class="tab tabs__list__item">
                <a href="/admin/internal-dashboard/" class="tabs__list__link">
                  <i
                    class="icon tabs__list__icon mi-24px mi-computer"
                    aria-hidden="true"
                  ></i>
                  {{ "chbAdmin.header.internalDashboard" | i18n }}
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div class="subheading" v-if="loggedInUser.isCHBAdmin">
        <h5>{{ "chbAdmin.subheader.title" | i18n }}</h5>
        <div class="addButtonAndSort">
          <div class="filterContainer">
            <span class="pagination__label">
              {{ "chbAdmin.subHeader.sort.label" | i18n }}
            </span>
            <div class="sort-action">
              <ChbSelect
                name="sortOrder"
                :selectData="sortOrder"
                :selectedValue="sortOrderBy"
                :isValid="sortOrderBy.isValid"
                :errorText="sortOrderBy.errorText"
                @select-change="sortCountries($event)"
                :editable="false"
              />
            </div>
          </div>
          <div class="buttonContainer theme--light">
            <a
              href="javascript:void(0)"
              role="button"
              class="button button--primary button--icon--left"
              @click="handleAddCountry"
            >
              <i
                class="icon button__icon mi-20px mi-plus"
                aria-hidden="true"
              ></i>
              {{ "chbAdmin.subheader.button.addCountry" | i18n }}
            </a>
          </div>
        </div>
      </div>
    </ChbSection>
  </div>
</template>

<script>
import ChbSection from "../common/ChbSection.vue";
import ChbSelect from "../common/ChbSelect.vue";
import i18nFilter from "../../filters/i18nFilter";
import { createFormObject } from "@/formUtilities";
import { mapActions, mapState } from "vuex";
import { eventBus } from "../eventBus/index";
import router from "@/router";

export default {
  name: "ChbPageHeader",
  components: {
    ChbSection,
    ChbSelect
  },
  data() {
    return {
      loggedInUser: JSON.parse(window.localStorage.getItem("loggedInUserInfo")),
      sortOrder: [
        i18nFilter("chbAdmin.subHeader.sort.asc"),
        i18nFilter("chbAdmin.subHeader.sort.desc")
      ],
      sortOrderBy: i18nFilter("chbAdmin.subHeader.sort.asc"),
    };
  },
  computed: {
    ...mapState(["chbCountries"])
  },
  methods: {
    ...mapActions(["openModal", "sortBy"]),
    handleAddCountry() {
      const modalOptions = {
        contentComponent: "ChbAddCountryForm",
        data: {
          isFormValidated: false,
          isFormValid: true,
          formFields: createFormObject(["countryName", "currencyCode"]),
          formValidators: {
            countryName: ["required", "countryNotInTheTable"],
            currencyCode: ["required"]
          }
        },
        headerText: i18nFilter("chbAdmin.modal.addCountry.header.label"),
        submitButtonOptions: {
          isDisabled: false,
          label: i18nFilter("chbAdmin.modal.addCountry.submit.button.label")
        },
        cancelButtonOptions: {
          isDisabled: false,
          label: i18nFilter("chbAdmin.modal.addCountry.cancel.button.label")
        }
      };

      this.openModal(modalOptions);
    },
    sortCountries(event) {
      this.sortOrderBy = event.fieldValue;
      eventBus.sortCountries(event.fieldValue, this.chbCountries);
    },
    logout() {
      window.localStorage.removeItem("[iam]id_token");
      window.localStorage.removeItem("loggedInUserInfo");
      router.push({ name: "Login" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/settings";

.tabs {
  &__list {
    margin-left: 0;
  }
  .tabs__list__link {
    padding: 12px 16px;
  }
  .active {
    color: #141414 !important;
    font-weight: 400;
  }
}

.admin-header {
  display: flex;
  justify-content: space-between;
  .actions {
    display: flex;
    gap: 8px;
    align-items: center;
    .logout-link {
      cursor: pointer;
      color: #0073ab;
      text-decoration: underline;
    }
  }
}

hr {
  max-width: unset;
}

.subheading {
  display: flex;
  justify-content: space-between;
  h5 {
    padding-top: 10px;
  }
  .addButtonAndSort {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    .filterContainer {
      margin-right: 21px;
      display: flex;
      justify-content: flex-end;
      span {
        display: flex;
        align-items: center;
        margin-right: 8px;
      }
      .form-input select:not([size]):not([multiple]) {
        padding-right: 52px;
      }
      .sort-action {
        width: 30%;
      }
    }
    .buttonContainer {
      .chb-button-dark-primary {
        border-radius: 2px;
        padding-bottom: 12px;
        padding-top: 12px;
        padding-left: 24px;
        padding-right: 24px;
        &:hover {
          background-color: #003e5e;
          color: #fefefe;
        }
        &:focus {
          background-color: #003e5e;
          color: #fefefe;
        }
      }
    }
  }
}

#header-container {
  width: 100%;
  text-align: center;
}

#header-country-configuration {
  float: left;
  width: 20%;
  border-bottom: 3px solid $chb-marine-blue;
  color: $chb-marine-blue;
  margin-top: 16px;
  margin-bottom: 24px;
  padding-top: 12px;
  padding-bottom: 16px;
}

#header-chb-contracts {
  display: inline-block;
  width: 20%;
  border-bottom: 3px solid $medium-gray;
  margin-top: 16px;
  margin-bottom: 24px;
  padding-top: 12px;
  padding-bottom: 16px;
}

#header-empty {
  float: right;
  width: 60%;
  border-bottom: 3px solid $medium-gray;
  margin-top: 16px;
  padding-top: 12px;
  padding-bottom: 16px;
  height: 55px;
}

#link-country-configuration {
  color: $chb-marine-blue;
  font-weight: bold;
  font-size: 16px;
}

#link-chb-contracts {
  color: $body-font-color;
  font-size: 16px;
}
</style>
