import axios from "axios";
import log from "../logger";
import applicationContext from "../ApplicationContext";
import { getAuthHeaders } from "@/authentication";

import countryFilter from "../filters/countryFilter";
import store from "../store";
  
export const getCountries = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: `${
          applicationContext.contentLocation
        }/customs-services/customs-rules/countries`,
        headers: getAuthHeaders()
      });

      const { result } = response.data;
      await store.dispatch("setLoaderState", false);
      return result;
    } catch (errorResponse) {
      throw errorResponse;
    }
  }

export const getCountry = async (countryCode) =>  {
    try {
      const response = await axios({
        method: "GET",
        url: `${
          applicationContext.contentLocation
        }/customs-services/customs-rules/countries/${countryCode}`,
        headers: getAuthHeaders()
      });
      const { result } = response.data;
      //replace %% to % - reason - not to break the Play framework in SSP as a single '%' is a special char in Play templates.
      for (let productGroup of result.productGroups) {
        if (productGroup.products.length) {
          productGroup.products.forEach(
            product => (
              product.productDescription = product.productDescription
                ? product.productDescription.replace(/%%/g, "%")
                : "")
          );
        }
      }
      await store.dispatch("setLoaderState", false);
      log.info("Received country", result);
      return result;
    } catch (error) {
      throw error;
    }
  }

export const  save = async (countryData) => {
    const countryName = countryFilter(countryData.countryCode);
    log.info(`Saving ${countryName}`, countryData);
    try {
      const method = store.state.editCountryController.isNewCountry
        ? "POST"
        : "PUT";
      const response = await axios({
        method,
        url: `${
          applicationContext.contentLocation
        }/customs-services/customs-rules`,
        headers: getAuthHeaders(),
        data: countryData
      });
      await store.dispatch("setLoaderState", false);
      if (response.status !== 200) {
        throw new Error();
      }

      log.info(`${countryName} has been saved`);
    } catch (errorResponse) {
      log.error(`Failed to save ${countryName}`);
      throw errorResponse;
    }
  }

export const  deleteCountry = async (countryCode) => {
    const countryName = countryFilter(countryCode);

    log.info(`Deleting ${countryName}`);
    try {
      const response = await axios({
        method: "DELETE",
        url: `${
          applicationContext.contentLocation
        }/customs-services/customs-rules/countries/${countryCode}`,
        headers: getAuthHeaders()
      });
      await store.dispatch("setLoaderState", false);
      if (response.status !== 200) {
        throw new Error();
      }

      log.info(`${countryName} has been deleted`);
    } catch (errorResponse) {
      log.error(`Failed to delete ${countryName}`);
      throw errorResponse;
    }
  }
  
export const getCities = async (city, countryCode) =>
  axios({
    method: "GET",
    url: `${applicationContext.contentLocation}/synergy/reference-data/geography/locations?type=city&countryCode=${countryCode}&cityName=${city}`,
    headers: getAuthHeaders()
  });
