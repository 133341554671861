<template>
  <div class="login-section">
    <div class="main-header">
      <img src="../assets/img/maersk-logo.svg" />
      <hr />
    </div>
    <div class="login-content">
      <h1>{{ "label.welcomeToPortal" | i18n }}</h1>
      <div class="buttonContainer theme--light">
        <a
          href="javascript:void(0)"
          role="button"
          class="button button--primary button--submit"
          @click="login"
        >
          {{ "label.login" | i18n }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { generateNonce } from "@maersk/shared-js";
import applicationContext from "../../src/ApplicationContext";
export default {
  name: "Login",
  methods: {
    login() {
      const hostName = applicationContext.env.includes("prod")
        ? `maersk.com`
        : `${applicationContext.env}.maersk.com`;
      window.location = `https://${hostName}/portaluser/login?redirect_uri=${window
        .location.origin + "/admin/"}&nonce=${generateNonce(10)}`;
    }
  }
};
</script>
<style lang="scss">
.login-section {
  margin-top: 20px;
  .main-header {
    hr {
      max-width: unset;
    }
  }
  .login-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;

    h1 {
      font-size: 36px;
    }
    a {
      margin-top: 20px;
    }
  }
}
</style>
