import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { constants } from "./utilities/CONSTANTS";
import clickoutside from "./directives/clickoutside";
import applicationContext from "./ApplicationContext";

import i18nFilter, { loadSelectedLang } from "./filters/i18nFilter";
import countryFilter from "./filters/countryFilter";
import { ignMenuInit } from "./ignMenu";
import { FeatureFlags } from "@maersk/vue-plugin-featureflags";
import {
  NativeEventSource,
  EventSourcePolyfill,
} from "event-source-polyfill/src/eventsource.min.js";
import mcModel from "@maersk-global/mds-vue-mc-model";

// loadSelectedLang(); // TODO need to change in CMS location for variable values

// EventSource polyfill for Launch Darkly plugin
window.EventSource = NativeEventSource || EventSourcePolyfill;

Vue.filter("i18n", i18nFilter);
Vue.filter("countryFilter", countryFilter);
Vue.directive("clickoutside", clickoutside);
Vue.directive("mc-model", mcModel);
Vue.config.productionTip = false;

Vue.use(FeatureFlags, {
  options: {
    evaluationReasons: true,
  },
  user: {
    brand: constants.LD_BRAND,
  },
  prefix: constants.LD_PREFIX,
});

// Init of ING 2 menu
ignMenuInit(applicationContext.assetsLocation);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#main");
