import Vue from "vue";
import Vuex from "vuex";

import actions from "./actions";
import mutations from "./mutations";

Vue.use(Vuex);

export const state = {
  chbCountries: [],
  modalController: {
    isVisible: false,
    contentComponent: null,
    data: null,
    headerText: "",
    modalWidth: "640px",
    submitButtonOptions: {
      isDisabled: false,
      label: ""
    },
    cancelButtonOptions: {
      isDisabled: false,
      label: ""
    },
    deleteButtonOptions: {
      isVisible: false,
      isDisabled: false,
      label: ""
    }
  },
  editCountryController: {
    formData: null,
    isNewCountry: false,
    isFormValidated: false,
    isFormValid: false,
    isFormDataChanged: false,
    isCurrencyCodeChanged: false,
    isEditEnabled: false,
    validators: {
      currencyCode: ["required"],
      countryContactEmail: ["required", "email"],
      countryContactPhone: ["required", "phoneNumber"],
      countryUploadDocumentEmail: ["required", "email"]
    }
  },
  showDeleteModal: false,
  productToDelete: {
    product: null,
    productGroup: ""
  },
  isLoading: false,
  isAddProductEnabled: false,
  overrideCurrentPortsData: false,
  legalEntity: {
    data: {
      name: "",
      address: ""
    },
  },
  additionalInformation: {
    enrollmentEmail: "",
    templates: ""
  },
  customDocs: []
};

export default new Vuex.Store({
  state,
  mutations,
  actions
});
