import countryFilter from "../filters/countryFilter";
import activePorts from "@/resources/activePorts.json";

let ports = {};

export const getAndMapPorts = async () => {
    try {
      ports = activePorts.ports.reduce((aggr, curr) => {
        const currentCountry = countryFilter(curr.countryName);
        if (!aggr[currentCountry]) {
          aggr[currentCountry] = [];
        }
        aggr[currentCountry].push({
          portName: curr.locationName,
          geoId: curr.geoId
        });
        return aggr;
      }, {});
      for (const country in ports) {
        if(country !== 'ML' && country !== 'BF' && country !== 'UG' && country !== 'NE') {
          ports[country].unshift({
            portName: "All Ports",
            geoId: "-999"
          });
        }
      }
    } catch (error) {
      ports = { error };
    }
};

export default countryCode => (countryCode ? ports[countryCode] : ports);
